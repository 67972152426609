import React, { useEffect, useRef, useState, useCallback } from "react";
import "./MultiStepForm.css";
import stepImage from "../assets/img/step-image.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle,faAngleLeft,faPlus,faTimes,faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {faCircle} from "@fortawesome/free-regular-svg-icons";
import {Formik,Form,Field,FormikValues,FormikConfig,FieldArray,ErrorMessage} from "formik";
import {Radio,FormControl,FormControlLabel,FormLabel,Box,IconButton,InputAdornment,OutlinedInput,InputLabel,Modal} from "@material-ui/core";
import {MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import {TextField, RadioGroup} from "formik-material-ui";
import {MuiThemeProvider, createTheme} from "@material-ui/core/styles";
import Loader from "react-js-loader";
import * as Yup from "yup";
import {useParams,useHistory} from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import frLocal from "date-fns/locale/fr";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import fr from "react-phone-input-2/lang/fr.json";
import {useMediaQuery} from "react-responsive";
import {ConnectedFocusError} from "focus-formik-error";
import {useDropzone} from "react-dropzone";
import axios from "axios";
import {CheckboxIcons, CheckboxIconsSuccess} from "./CheckboxIcons";
import affiliationImage from "../assets/img/CodesAffiliation.png";
import linkIcon from "../assets/img/Link.svg";
import {API_URL_BACK,regex,initialValues} from "../config";
import CircularProgress from '@material-ui/core/CircularProgress';

export function MultiStepForm() {
  const { regkey } = useParams();
  let history = useHistory();
  const [formValues, setFormValues] = useState(initialValues);
  console.log("formValues ->",formValues);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [otherForm, setOtherForm] = useState(false);
  const [sendForm, setSendForm] = useState(false);
  const [haveChild, setHaveChild] = useState(false);
  const [havePartenaire, setHavePartenaire] = useState(false);
  const [insurePartenaire, setInsurePartenaire] = useState(false);

  const [partenaireShow, setPartenaireShow] = useState(true);
  const [childShow, setChildShow] = useState(true);
  const [contract,setContract] = useState("default");
  const [fileKey,setFileKey] = useState(null);
  const [allContract,setAllContract] = useState([]);
  const [fileData,setFileData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loader,setLoader] = useState(false);
  const [messageErr,setMessageErr] = useState({display:false,message:''});

  const formRef = useRef();

  const hiddenFileInput = React.useRef(null);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    fetch(`${API_URL_BACK}/start/user/register?regkey=${regkey}`)
      .then((res) => {
        if (res.status === 404) {
          window.location.href = "https://www.green-sante.fr";
        }
        return res.json();
      })
      .then((result) => {
        if (result.data.savedata) {
          setFormValues(JSON.parse(result.data.savedata));
          setOtherForm(JSON.parse(result.data.savedata).other === "true" && true);
          setSendForm(JSON.parse(result.data.savedata).transferEmail === "true" && true);
          setHaveChild(JSON.parse(result.data.savedata).haveChilds === "true" && true);
          setHavePartenaire(JSON.parse(result.data.savedata).situation !== "0" && true);
          setInsurePartenaire(JSON.parse(result.data.savedata).insurePartenaire === "true" && true);
        }
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .then(() => {})
      .catch((err) => {
        console.log("erreur");
      });
  }, [regkey]);

  useEffect(()=> {
    axios.get(`${API_URL_BACK}/user/registration/contract?regkey=${regkey}`)
    .then(function (response) {
      const res = response.data.data;
      setAllContract(res.contribution);
    })
    .catch(function (error) {
      console.log("erreur ->",error);
    });
  },[]);

  const ContractAndFile = async e =>{
    if(e.target.value === "default") setFileKey(null);
    else{
      for(let i = 0; i < allContract.length; i++){
        if(allContract[i].contract_name === e.target.value && e.target.value !== "default" && e.target.value !== contract)setFileKey(i);
      }
      setContract(e.target.value);
    }
  } 

  useEffect(() => {
    if(allContract.length !== 0){
      if(fileKey !== null){
        const file = allContract[fileKey].files;
        if(file.length !== 0){
          
          if(file.length > 1){
            setFileData([]);
            for(let i = 0; i < file.length; i++){
              setFileData(fileData => [...fileData,{url:file[i].url,fileName:file[i].name}]);
            }

          } else setFileData([{url:file[0].url,fileName:file[0].name}]);

        } else setFileData([]);
      }
    }
  },[fileKey]);

  const formatDate = (dateSelected) => {
    const date = new Date(dateSelected);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const newDate = yyyy + '-' + mm + '-' + dd;
    return newDate;
  }

  function sendRegistration(){
    const formValue = formRef.current.values;

    const adressAssure = JSON.stringify({
      road_1: formValue.adress,
      postcode: formValue.postalCode,
      city: formValue.city,
    });

    const salarie = {
      registrationkey : regkey,
      address: adressAssure,
      email: formValue.email,
      password : formValue.password,
      phone: formValue.phone,
      firstname: formValue.firstname,
      lastname: formValue.lastname,
      ownersecunumber: formValue.socialNumber,
      contract_company_id : allContract[0].contract_company_id, 
      birthname: formValue.bornName,
      birthplace: formValue.bornPlace,
      date_start : formatDate(formValue.dateStart),
      birthdate: formatDate(formValue.bornDate),
      country: formValue.country,
      marital_status_id: parseInt(formValue.situation),
      noemie: formValue.noemieSystem,
      cpamRegime : formValue.cpamRegime,
      cpamCaisse : formValue.cpamCaisse,
      cpamCentre : formValue.cpamCentre.slice(0, -1),
      cpamCle : formValue.cpamCentre[3] || null,
      laser : formValue.laser,
      iban: formValue.iban,
      postcode: formValue.postalCode,
      spouse: formValue.insurePartenaire
    };
    
    // Enregistrer le salarié
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ registrationkey: regkey, ...salarie })
    };
    fetch(`${API_URL_BACK}/start/user/registration`, requestOptions)
      .then((response) => {
        //console.log("le retour AGEO ->",response);
        // Boucler sur le partenaire et enfant(s)
        if (haveChild) {
          const childs = formValue.childs;

          childs.map((child) => {
            const date1 = new Date();
            const date2 = new Date(child.bornDate);

            const childFormat = {
              recipienttype:
                date1.getFullYear() - date2.getFullYear() >= 18 ? 4 : 3,
              firstname: child.firstname,
              lastname: child.lastname,
              birthdate: child.bornDate,
              email: child.email,
              secunumber: child.socialNumber,
              iban: child.rib,
              codeaff1: child.affiliationCode,
              codeaff2: child.affiliationCode,
              codeaff3: child.affiliationCode,
              noemie: child.noemieSystem,
              date_start: formatDate(formValue.dateStart),
            };

            const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ registrationkey: regkey, ...childFormat })
            };

            fetch(`${API_URL_BACK}/start/user/recipient`, requestOptions)
              .then((response) => {
                response.json();
              })
              .catch((err) => err.json());
          });
        }

        if (insurePartenaire) {
          const partenaire = formValue.partenaire;

          const partenaireFormat = {
            recipienttype: formValue.situation === "2" ? 2 : 1,
            firstname: partenaire.firstname,
            lastname: partenaire.lastname,
            birthdate: partenaire.bornDate,
            email: partenaire.email,
            secunumber: partenaire.socialNumber,
            iban: partenaire.rib,
            codeaff1: partenaire.affiliationCode,
            codeaff2: partenaire.affiliationCode,
            codeaff3: partenaire.affiliationCode,
            noemie: partenaire.noemieSystem,
            date_start: formatDate(formValue.dateStart),
          };

          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              registrationkey: regkey,
              ...partenaireFormat
            })
          };
          fetch(`${API_URL_BACK}/start/user/recipient`, requestOptions)
            .then((response) => {
              response.json();
            })
            .catch((err) => err.json());
        }

        // TODO : Boucler les documents sur la route https://apiback.green-sante.com/start/user/file
        if (files.length > 0) {
          files.map((file) => {
            getBase64(file, (base64) => {
              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  registrationkey: regkey,
                  comment: "",
                  file: base64
                })
              };
              fetch(`${API_URL_BACK}/start/user/file`, requestOptions)
                .then((response) => {
                  response.json();
                })
                .catch((err) => err.json());
            });
          });
        }
        if(response.status === 200) history.push("/success");
        else {
          response.json().then((error) => {
            setLoader(false);
            setMessageErr({ display: true, message: "Erreur: "+error.message})
          })
        }
      })
      .catch((err) => err.json());
  }

  // FIN DE LA FONCTION
  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const saveDatas = (event) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        registrationkey: regkey,
        savedata: JSON.stringify(formRef.current.values)
      })
    };
    fetch(`${API_URL_BACK}/start/user/regkey`, requestOptions)
      .then((response) => response.json())
      .catch((err) => err.json());
  };

  const handleTransferRegistration = () => {
    if (otherForm) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          registrationkey: regkey,
          email: formRef.current.values.emailOther
        })
      };
      fetch(
        `${API_URL_BACK}/start/user/transferregistration`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => console.log(res))
        .catch((err) => err.json());
    }
    if (sendForm) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          registrationkey: regkey,
          email: formRef.current.values.myEmail
        })
      };
      fetch(
        `${API_URL_BACK}start/user/transferregistration`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => console.log(res))
        .catch((err) => err.json());
    }
  };

  // const { getLastActiveTime } = useIdleTimer({
  //   timeout: 5000,
  //   onIdle: saveDatas,
  //   debounce: 500
  // });

  // const handleClickHiddenInput = (event) => {
  //   //hiddenFileInput.current.click();
  // };

  // const uploadFiles = (event) => {
  //   let tempFiles = files;
  //   tempFiles = [...tempFiles, event.currentTarget.files[0]];
  //   setFiles(tempFiles);
  // };

  const onDrop = useCallback(
    (acceptedFiles) => {
      let tempFiles = files;
      acceptedFiles.map((file) => {
        tempFiles = [...tempFiles, file];
      });
      setFiles(tempFiles);
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop, accept: ".doc,.docx,.pdf,.xls,.jpg,.png"});

  function handleFileDelete(index): void {
    let tempFiles = [...files];
    tempFiles.splice(index, 1);
    setFiles(tempFiles);
  }

  if (loading) {
    return (
      <div className="centered-div">
        <Loader type="spinner-default" bgColor={"#6CA21F"} title={"Veuillez patienter..."} color="black" size={50}/>
      </div>
    );
  }

  return (
    <FormikStepper fileKey={fileKey} saveDatas={saveDatas} otherForm={otherForm} sendForm={sendForm} setShowChild={setChildShow} setShowPartenaire={setPartenaireShow} submitLoading={submitLoading} initialValues={formValues} loader={loader} setLoader={setLoader} messageErr={messageErr} contract={contract}
      onSubmit={async (values) => {
        console.log("what a values ->",values);
        setLoader(true);
        setSubmitLoading(true);
        setTimeout(() => {
          if (otherForm || sendForm) {
            handleTransferRegistration();
          }
          sendRegistration();
          setSubmitLoading(false);
        }, 2000);
      }}
      innerRef={formRef}
    >
      <FormikStep
        validationSchema={Yup.object({
          emailOther: Yup.mixed().when("other", {
            is: "true",
            then: Yup.string().email("L'email n'est pas valide").required("Ce champ est obligatoire"),
            otherwhise: null
          }),
          myEmail: Yup.mixed().when("transferEmail", {
            is: "true",
            then: Yup.string().email("L'email n'est pas valide").required("Ce champ est obligatoire"),
            otherwhise: null
          })
        })}
      >
        <Box>
          <h1>Pour Commencer</h1>
        </Box>

        <div style={{ marginBottom: 20 }}>
          <Box>
            <FormLabel component="legend">Souhaitez-vous qu’une autre personne remplisse à votre place ?</FormLabel>
            <Field component={RadioGroup} name="other">
              <div style={{ display: "flex" }}>
                <FormControlLabel value="true" control={ <Radio color="primary" onClick={() => { setOtherForm(true); setSendForm(false); formRef.current.setFieldValue("transferEmail", "false");}}/> } label="Oui"/>
                <FormControlLabel value="false" control={ <Radio color="primary" onClick={() => setOtherForm(false)}/> } label="Non" />
              </div>
            </Field>
          </Box>

          {otherForm &&
            <Box style={{ width: isTabletOrMobile ? null : 420 }}>
              <Field fullWidth name="emailOther" component={TextField} label="Saissez son adresse email"/>
            </Box>
          }
        </div>

        {!otherForm &&
          <>
            <Box>
              <FormLabel component="legend">Souhaitez-vous transférer ce formulaire sur votre adresse email ?</FormLabel>
              <Field component={RadioGroup} name="transferEmail">
                <div style={{ display: "flex" }}>
                  <FormControlLabel value="true" control={ <Radio color="primary" onClick={() => setSendForm(true)}/> } label="Oui" />
                  <FormControlLabel value="false" control={ <Radio color="primary" onClick={() => setSendForm(false)} /> }label="Non"/>
                </div>
              </Field>
            </Box>

            {sendForm && 
              <Box style={{ width: isTabletOrMobile ? null : 420 }}>
                <Field fullWidth name="myEmail" component={TextField} label="Saissez votre adresse email"/>
              </Box>
            }
          </>
        }
      </FormikStep>

      <FormikStep
        validationSchema={Yup.object({
          lastname: Yup.string().ensure().required("Vous devez renseigner votre nom").matches(regex.letterOnly,"nom non valide"),
          firstname: Yup.string().ensure().required("Vous devez renseigner votre prénom").matches(regex.letterOnly,"prénom non valide"),
          bornDate: Yup.string().required("Vous devez renseigner votre date de naissance").ensure(),
          dateStart: Yup.string().ensure().required("Vous devez renseigner la date du début de votre contrat"),
          bornPlace: Yup.string().ensure().required("Vous devez renseigner votre ville de naissance").matches(regex.city,"ville de naissance non valide"),
          email: Yup.string().email("L'email n'est pas valide").ensure().required("Vous devez renseigner votre email"),
          city: Yup.string().ensure().required("Vous devez renseigner la ville dans laquelle vous habitez").matches(regex.city,"ville non valide"),
          adress: Yup.string().ensure().required("Vous devez renseigner votre adresse postale"),
          postalCode: Yup.string().ensure().required("Vous devez renseigner votre code postale"),
          country: Yup.string().ensure().required("Vous devez renseigner votre pays").matches(regex.letterOnly,"pays non valide"),
          socialNumber: Yup.string().required("Vous devez renseigner votre numéro de sécurité sociale ").matches(regex.secu,"numéro de sécurité sociale non valide").ensure(),
          cpamRegime: Yup.string().required("Champs requis").matches(regex.regime,"chiffre").matches(regex.twoCharacter,"2 valeurs maximum").ensure(),
          cpamCaisse: Yup.string().required("Champs requis").matches(regex.regime,"chiffre").matches(regex.threeCharacter,"3 valeurs maximum").ensure(),
          cpamCentre: Yup.string().required("Champs requis").matches(regex.regime,"chiffre").matches(regex.fourCharacter,"4 valeurs maximum").ensure(),
          laser: Yup.string().required("Champs requis").matches(regex.regime,"chiffre").matches(regex.oneCharacter,"une valeurs maximum").ensure(),
          affiliationCode: Yup.string().ensure().required("Vous devez renseigner votre code d'affiliation"),
          phone: Yup.string().matches(regex.phone,"numéro de téléphone non valide"),
          iban: Yup.string().matches(regex.iban, "numéro D'IBAN non valide").ensure().required("Vous devez renseigner votre numéro IBAN"),
          password: Yup.string().matches(regex.passwordLength,"minimum 6 caractère").required("Champs requis"),
          confirmPassword: Yup.string().required("Champs requis").oneOf([Yup.ref('password'), null], 'Différent du mots de passe saisie')
        })}
      >
        <Box>
          <h1>Identification de l'assuré</h1>
        </Box>

        <Box>
          <p>Les informations ci-dessous nous permettront de vous contacter en cas de besoin.</p>
        </Box>

        <div style={{display: "flex",flexDirection: isTabletOrMobile ? "column" : "row",marginBottom: 20}} >
          <div style={{flex: 1,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}} >
            <Field fullWidth name="bornName" variant="outlined" component={TextField} label="Nom de naissance"/>
          </div>
          <div style={{flex: 1,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}} >
            <Field fullWidth name="lastname" variant="outlined" component={TextField} label="Nom d’usage"/>
          </div>
          <div style={{ flex: 1 }}>
            <Field fullWidth name="firstname" variant="outlined" component={TextField} label="Prénom"/>
          </div>
        </div>

        <div style={{display: "flex",flexDirection: isTabletOrMobile ? "column" : "row",marginBottom: 20}} >
          <div style={{flex: 2,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}} >
            <Field fullWidth name="phone" variant="outlined" type="number" component={CustomPhoneInput} label="Numéro de téléphone"/>
            <ErrorMessage name="phone">
              {(msg) => (
                <div style={{color: "#f44336",marginLeft: 14,marginRight: 14,fontSize: "0.75rem",marginTop: 3,lineHeight: 1.66,fontFamily: "Raleway"}} >
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>
          <div style={{ flex: 3 }}>
            <Field fullWidth name="email" variant="outlined" component={TextField} label="Adresse Email"/>
          </div>
        </div>

        <div style={{display: "flex",flexDirection: isTabletOrMobile ? "column" : "row",marginBottom: 20}} >
          <div style={{flex: 1,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}} >
            <Field fullWidth variant="outlined" component={CustomDatePicker} label="label" name="bornDate"/>
            <ErrorMessage name="bornDate">
              {(msg) => (
                <div style={{color: "#f44336",marginLeft: 14,marginRight: 14,fontSize: "0.75rem",marginTop: 3,lineHeight: 1.66,fontFamily: "Raleway"}}>
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>

          <div style={{flex: 1,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0 }} >
            <Field fullWidth name="bornPlace" variant="outlined" component={TextField} label="Lieu de naissance"/>
          </div>
          <div style={{ flex: 1 }}>
            <Field fullWidth name="socialNumber" variant="outlined" component={TextField} type="number" label="Numéro de Sécurité Sociale"/>
          </div>
        </div>

        <div style={{display: "flex", flexDirection: isTabletOrMobile ? "column" : "row", marginBottom: 20}} >
          <div style={{flex: 1,marginBottom: isTabletOrMobile ? 20 : 0 }}>
            <Field fullWidth name="adress" variant="outlined" component={TextField} label="Adresse"/>
          </div>
        </div>

        <div style={{display: "flex",flexDirection: isTabletOrMobile ? "column" : "row",marginBottom: 20}}>
          <div style={{ flex: 0.5 , marginRight: isTabletOrMobile ? 0 : 15, marginBottom: isTabletOrMobile ? 20 : 0}}>
            <Field fullWidth name="postalCode" variant="outlined" component={TextField} type="number" label="Code Postal"/>
          </div>
          <div style={{ flex: 1, marginRight: isTabletOrMobile ? 0 : 15, marginBottom: isTabletOrMobile ? 20 : 0 }} >
            <Field fullWidth name="city" variant="outlined" component={TextField} label="Ville" />
          </div>
          <div style={{ flex: 1 }}>
            <Field fullWidth name="country" variant="outlined" component={TextField} label="Pays"/>
          </div>
        </div>
          {/* DIV VIDE */}
        <div style={{ height: "2px", width: "100%", backgroundColor: "#C4C4C4", marginTop: 40, marginBottom: 40}}></div>

        <div style={{ display: "flex",flexDirection: isTabletOrMobile ? "column" : "row",marginBottom: isTabletOrMobile ? 0 : 20}} >
          
          <div style={{flex: 1.5,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}} >
            <Field fullWidth variant="outlined" component={CustomDateStart} label="label" name="dateStart"/>
            <ErrorMessage name="dateStart">
              {(msg) => (
                <div style={{color: "#f44336",marginLeft: 14,marginRight: 14,fontSize: "0.75rem",marginTop: 3,lineHeight: 1.66,fontFamily: "Raleway"}}>
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>
          
          <div style={{borderRadius:"17px", display: "flex",flexDirection :"row",flex: 2,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}} >
            
            <div style={{ flex: 3,marginRight:3}}>
              <Field fullWidth name={"cpamRegime"} variant="outlined" component={TextField} label="Régime" />
            </div>
            <div style={{ flex: 3,marginRight:3}}>
              <Field fullWidth name={"cpamCaisse"} variant="outlined" component={TextField} label="Caisse" />
            </div>
            <div style={{ flex: 3}}>
              <Field fullWidth name={"cpamCentre"} variant="outlined" component={TextField} label="Guichet" />
            </div>
              
            <div style={{height:"100%",marginTop: isTabletOrMobile ? 30 :"3vh",marginLeft: isTabletOrMobile ? 2 : 0}}>
              <InputAdornment position="start">
                <IconButton aria-label="toggle password visibility" onClick={() => setShowModal(true)} edge="end">
                  <FontAwesomeIcon icon={faInfoCircle} color="#848484" style={{ fontSize: 20 }} />
                </IconButton>
              </InputAdornment>
            </div>

            <Modal open={showModal} onClose={() => setShowModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box onClick={() => setShowModal(false)} style={{position: "absolute",top: "50%",left: "50%",transform: "translate(-50%, -50%)",borderRadius: 17 }}>
                <img style={{width: isTabletOrMobile ? "100vw" : null,borderRadius: 17}} src={affiliationImage} alt="Logo"/>
              </Box>
            </Modal>
          </div>

          <div style={{ flex: 0.8, marginRight: "1vw",marginBottom: isTabletOrMobile ? 20 : 0}}>
            <Field fullWidth name={"laser"} variant="outlined" component={TextField} label="Rang Laser"/>
          </div>
        </div>
        
        <div style={{display: "flex", flexDirection: isTabletOrMobile ? "column" : "row", marginBottom: 20}} >
          <div style={{flex: 0.5}}>
              <Field fullWidth name={"iban"} variant="outlined" component={TextField} label="IBAN ( Utile pour vos remboursements )"/>
          </div>
        </div>
        
        <div style={{display: "flex", flexDirection: isTabletOrMobile ? "column" : "row", marginBottom: 20}} >
          <div style={{flex: 0.5, marginRight: isTabletOrMobile ? 0 : 15, marginBottom: isTabletOrMobile ? 20 : 0}}>
              <Field type="password" fullWidth name={"password"} variant="outlined" component={TextField} label="Nouveau mot de passe"/>
          </div>
          <div style={{flex: 0.5, marginBottom: isTabletOrMobile ? 20 : 0}}>
              <Field type="password" fullWidth name={"confirmPassword"} variant="outlined" component={TextField} label="Confirmation du mot de passe"/>
          </div>
        </div>
        <Box paddingBottom={1} paddingTop={1}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Situation de l’adhérent ?</FormLabel>
            <Field component={RadioGroup} name="situation">
              <div style={{display: "flex",flexDirection: isTabletOrMobile ? "column" : "row"}} >
                <FormControlLabel value={"1"} control={ <Radio color="primary" onClick={() => { setHavePartenaire(true); }} /> } label="Marié"/>
                <FormControlLabel value={"2"} control={ <Radio  color="primary" onClick={() => { setHavePartenaire(false); formRef.current.setFieldValue("insurePartenaire","false"); setInsurePartenaire(false); }}/>} label="Célibataire, veuf, divorcé" />
                <FormControlLabel value={"3"} control={ <Radio color="primary" onClick={() => { setHavePartenaire(true); }} /> } label="PACS ou concubinage" />
              </div>
            </Field>
          </FormControl>
        </Box>

        <Box paddingBottom={1} paddingTop={1}>
          <FormControl component="fieldset">
            <FormLabel component="legend"> Souhaitez-vous la mise en place du système Noémie (remboursements plus rapide) ? </FormLabel>
            <Field component={RadioGroup} name="noemieSystem">
              <div style={{ display: "flex" }}>
                <FormControlLabel value={"false"} control={<Radio color="primary" />} label="Non" />
                <FormControlLabel value={"true"} control={<Radio color="primary" />} label="Oui"/>
              </div>
            </Field>
          </FormControl>
        </Box>

        {havePartenaire && (
          <Box paddingBottom={1} paddingTop={1}>
            <FormControl component="fieldset">
              <FormLabel component="legend"> Voulez-vous assurer votre conjoint ? </FormLabel>
              <Field component={RadioGroup} name="insurePartenaire">
                <div style={{ display: "flex" }}>
                  <FormControlLabel value={"true"} control={<Radio color="primary" onClick={() => setInsurePartenaire(true)} />} label="Oui"/>
                  <FormControlLabel value={"false"} control={ <Radio color="primary" onClick={() => setInsurePartenaire(false)} /> } label="Non"/>
                </div>
              </Field>
            </FormControl>
          </Box>
        )}
      </FormikStep>

      <FormikStep
        validationSchema={Yup.object({
          
          partenaire: Yup.object({
            lastname: Yup.string().ensure().required("Vous devez renseigner le nom du partenaire"),
            firstname: Yup.string().ensure().required("Vous devez renseigner le prénom du partenaire"),
            bornDate: Yup.string().ensure().required("Vous devez renseigner la date de naissance du partenaire"),
            email: Yup.string().email("L'email n'est pas valide").ensure().required("Vous devez renseigner l'email du partenaire"),
            socialNumber: Yup.string().required("Vous devez renseigner le numéro de sécurité sociale du partenaire").matches(regex.secu,"Le numéro de sécurité sociale n'est pas valide").ensure(),
            affiliation: Yup.string().ensure().required("Vous devez renseigner le code d'affiliation du partenaire"),
            rib: Yup.string().matches(regex.iban, "Le numéro IBAN n'est pas valide").ensure().required("Vous devez renseigner le RIB du partenaire")
          }),

          childs: Yup.array().of(
            Yup.object({
              lastname: Yup.string().ensure().required("Vous devez renseigner le nom de l'enfant"),
              firstname: Yup.string().ensure().required("Vous devez renseigner le prénom de l'enfant"),
              bornDate: Yup.string().ensure().required("Vous devez renseigner le prénom de l'enfant"),
              email: Yup.string().email("L'email n'est pas valide").ensure().required("Vous devez renseigner l'email de l'enfant")
              /*socialNumber: Yup.string()
                                    .matches(regex.secu, 'Le numéro de sécurité sociale n\'est pas valide')
                                    .ensure()
                                    .required("Vous devez renseigner le numéro de sécurité sociale de l'enfant"),
                                affiliation: Yup.string()
                                    .ensure()
                                    .required("Vous devez renseigner le code d'affiliation de l'enfant"),
                                rib: Yup.string()
                                    .required("Vous devez renseigner le RIB de l'enfant")
                                    .matches(regex.iban, 'Le numéro IBAN n\'est pas valide')
                                    .ensure()*/
            })
          )
        })}
      >
        <Box>
          <h1>Vos bénéficiaires</h1>
        </Box>

        {insurePartenaire && (
          <div style={{ marginBottom: 20 }}>
            <Box style={{display: "flex",flexDirection: isTabletOrMobile ? "column" : "row",alignItems: isTabletOrMobile ? "flex-start" : "center",justifyContent: "space-between"}} >
              <h2 style={{ marginBottom: isTabletOrMobile ? 5 : null }}>Conjoint, concubin(e) ou partenaire de Pacs</h2>
            </Box>

            {partenaireShow && (
              <>
                <div style={{display: "flex",flexDirection: isTabletOrMobile ? "column" : "row",marginBottom: 20}}>
                  <div style={{flex: 1,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}}>
                    <Field fullWidth name="partenaire.lastname" variant="outlined" component={TextField} label="Nom" />
                  </div>
                  <div style={{flex: 1,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}}>
                    <Field fullWidth name="partenaire.firstname" variant="outlined" component={TextField} label="Prénom"/>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Field fullWidth variant="outlined" component={CustomDatePicker} label="label" name="partenaire.bornDate"/>
                    <ErrorMessage name="partenaire.bornDate">
                      {(msg) => (
                        <div style={{color: "#f44336",marginLeft: 14,marginRight: 14,fontSize: "0.75rem",marginTop: 3,lineHeight: 1.66,fontFamily: "Raleway"}}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div style={{display: "flex",flexDirection: isTabletOrMobile ? "column" : "row",marginBottom: 20}}>
                  <div style={{flex: 2,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}} >
                    <Field fullWidth name="partenaire.email" variant="outlined" component={TextField} label="Adresse Email"/>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Field fullWidth name="partenaire.socialNumber" variant="outlined" component={TextField} type="number" label="Numéro de Sécurité Sociale"/>
                  </div>
                </div>

                <div style={{display: "flex",flexDirection: isTabletOrMobile ? "column" : "row",marginBottom: 20}}>
                  <div style={{flex: 1,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}}>
                    <Field fullWidth name="partenaire.affiliation" variant="outlined" component={TextFieldAffiliationCode} type="number" label="Codes Affiliations"/>
                    <ErrorMessage name="partenaire.affiliation">
                      {(msg) => (
                        <div style={{color: "#f44336",marginLeft: 14,marginRight: 14,fontSize: "0.75rem",marginTop: 3,lineHeight: 1.66,fontFamily: "Raleway"}}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div style={{ flex: 2 }}>
                    <Field fullWidth name="partenaire.rib" variant="outlined" component={TextField} label="RIB"/>
                  </div>
                </div>

                <Box>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Souhaitez-vous la mise en place du système Noémie
                      (remboursements plus rapide) ?
                    </FormLabel>
                    <Field component={RadioGroup} name="partenaire.noemieSystem">
                      <div style={{ display: "flex" }}>
                        <FormControlLabel value={"true"} control={<Radio color="primary" />} label="Oui" />
                        <FormControlLabel value={"false"} control={<Radio color="primary" />} label="Non" />
                      </div>
                    </Field>
                  </FormControl>
                </Box>
              </>
            )}
          </div>
        )}

        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Avez-vous des enfants à charge ?
            </FormLabel>
            <Field component={RadioGroup} name="haveChilds">
              <div style={{ display: "flex" }}>
                <FormControlLabel value={"true"}
                  control={
                    <Radio color="primary"
                      onClick={() => {
                        setHaveChild(true);
                        formRef.current.setFieldValue("childs", [{firstname: "",lastname: "",bornDate: null,email: "",socialNumber: "",affiliation: "",rib: "",noemieSystem: "false",childAttachement: null}]);
                      }}
                    />
                  }
                  label="Oui"
                />
                <FormControlLabel value={"false"} label="Non"
                  control={
                    <Radio color="primary" onClick={() => { setHaveChild(false); formRef.current.setFieldValue("childs", []); }} />
                  }
                />
              </div>
            </Field>
          </FormControl>
        </Box>

        {haveChild && (
          <>
            <Box style={{display: "flex",flexDirection: isTabletOrMobile ? "column" : "row",alignItems: isTabletOrMobile ? "flex-start" : "center",justifyContent: "space-between"}}>
              <h2 style={{ marginBottom: isTabletOrMobile ? 5 : null }}> Les enfants à charge </h2>
            </Box>
            {childShow && (
              <>
                <FieldArray name="childs">
                  {(fieldArrayProps) => {
                    const { push, remove, form } = fieldArrayProps;
                    const { values } = form;
                    const { childs } = values;

                    return (
                      <div>
                        {childs.map((child, index) => {
                          function getAge(dateString) {
                            var today = new Date();
                            var birthDate = new Date(dateString);
                            var age =
                              today.getFullYear() - birthDate.getFullYear();
                            var m = today.getMonth() - birthDate.getMonth();
                            if (
                              m < 0 ||
                              (m === 0 && today.getDate() < birthDate.getDate())
                            ) {
                              age--;
                            }
                            return age;
                          }

                          return (
                            <div key={index}>
                              <Box style={{display: "flex",alignItems: "center"}}>
                                <h2>Enfant {index + 1}</h2>
                                <h4 style={{color: "#6CA21F",cursor: "pointer",marginLeft: 10}} onClick={() => remove(index)}>Supprimer</h4>
                              </Box>

                              <div style={{display: "flex",flexDirection: isTabletOrMobile? "column": "row",marginBottom: 20}}>
                                <div style={{flex: 1,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}}>
                                  <Field fullWidth name={"childs[" + index + "].lastname"} variant="outlined" component={TextField} label="Nom"/>
                                </div>
                                <div style={{flex: 1,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}}>
                                  <Field fullWidth name={"childs[" + index + "].firstname"} variant="outlined" component={TextField} label="Prénom"/>
                                </div>
                                <div style={{ flex: 1 }}>
                                  <Field fullWidth name={"childs[" + index + "].bornDate"} variant="outlined" component={CustomDatePicker} label="label"/>
                                  <ErrorMessage name={"childs[" + index + "].bornDate"}>
                                    {(msg) => (
                                      <div style={{color: "#f44336",marginLeft: 14,marginRight: 14,fontSize: "0.75rem",marginTop: 3,lineHeight: 1.66,fontFamily: "Raleway"}}>
                                        {msg}
                                      </div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </div>

                              <div style={{ display: "flex", flexDirection: isTabletOrMobile ? "column" : "row",marginBottom: 20}}>
                                <div style={{flex: 2, marginRight: isTabletOrMobile || getAge(child.bornDate) < 18 ? 0 : 15, marginBottom: isTabletOrMobile ? 20 : 0}}>
                                  <Field fullWidth name={"childs[" + index + "].email"} variant="outlined" component={TextField} label="Adresse Email"/>
                                </div>
                                {getAge(child.bornDate) >= 18 &&
                                  <div style={{ flex: 1 }}>
                                    <Field fullWidth name={"childs[" + index + "].socialNumber"} type="number" variant="outlined" component={TextField} label="Numéro de Sécurité Sociale"/>
                                  </div>
                                }
                              </div>

                              {getAge(child.bornDate) >= 18 &&
                                <div style={{display: "flex",flexDirection: isTabletOrMobile ? "column": "row", marginBottom: 20}}>
                                  <div style={{flex: 1,marginRight: isTabletOrMobile ? 0 : 15,marginBottom: isTabletOrMobile ? 20 : 0}}>
                                    <Field fullWidth name={"childs[" + index + "].affiliation"} type="number" variant="outlined" component={TextFieldAffiliationCode} label="Codes Affiliations"/>
                                    <ErrorMessage name={"childs[" + index + "].affiliation"}>
                                      {(msg) => (
                                        <div style={{color: "#f44336",marginLeft: 14,marginRight: 14,fontSize: "0.75rem",marginTop: 3,lineHeight: 1.66,fontFamily: "Raleway"}}>
                                          {msg}
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                  <div style={{ flex: 2 }}>
                                    <Field fullWidth name={"childs[" + index + "].rib"} variant="outlined" component={TextField} label="RIB"/>
                                  </div>
                                </div>
                              }

                              {getAge(child.bornDate) < 18 &&
                                <Box marginBottom={2}>
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend">A qui voulez-vous rattacher cet enfant ?{" "}</FormLabel>
                                    <Field component={RadioGroup} name={"childs[" + index + "].childAttachement"}>
                                      <div style={{ display: "flex" }}>
                                        <FormControlLabel value={"1"} control={<Radio color="primary" />} label="L'assuré"/>
                                        {insurePartenaire && <FormControlLabel value={"2"} control={<Radio color="primary" />} label="Le conjoint, concubin(e) ou partenaire de Pacs"/> }
                                      </div>
                                    </Field>
                                  </FormControl>
                                </Box>
                              }

                              <Box>
                                <FormControl component="fieldset">
                                  <FormLabel component="legend"> Souhaitez-vous la mise en place du système Noémie (remboursements plus rapide) ?</FormLabel>
                                  <Field component={RadioGroup} name={"childs[" + index + "].noemieSystem"}>
                                    <div style={{ display: "flex" }}>
                                      <FormControlLabel value={"false"} control={<Radio color="primary" />} label="Non"/>
                                      <FormControlLabel value={"true"} control={<Radio color="primary" />} label="Oui" />
                                    </div>
                                  </Field>
                                </FormControl>
                              </Box>
                            </div>
                          );
                        })}
                        
                        <Box paddingTop={2}>
                          <button className="button-add-child" type="button"
                            onClick={() =>push({firstname: "",lastname: "",bornDate: null,email: "",socialNumber: "",affiliation: "",rib: "",noemieSystem: "false",childAttachement: "1"})}
                          >
                            <FontAwesomeIcon icon={faPlus} color="#848484" style={{ fontSize: 20 }}/>
                          </button>
                        </Box>

                      </div>
                    );
                  }}
                </FieldArray>
              </>
            )}
          </>
        )}

        <Box marginTop={10}>
          <p style={{ fontWeight: "bold" }}>Documents à joindre obligatoirement pour l’ensemble des bénéficiaires :</p>
          <p>Pour les concubins, un justificatif de domicile commun, pour les PACS, une copie du Pacte.</p>
          <p>Pour les enfants majeurs: une photocopie de l’attestation d’assurance maladie de moins de trois mois, un certificat de scolarité ou la photocopie du contrat de formation ou apprentissage.*</p>
          <p>Pour les enfants handicapés: une copie du document accordant l’allocation d’éducation pour enfants handicapés ou l’allocation aux adultes handicapés.*</p>
          <p style={{ fontSize: 12 }}><span style={{ fontSize: 23 }}>*</span>Ces documents peuvent nous être envoyer directement ou bien d’ici quelques temps.</p>

          <input ref={hiddenFileInput} accept=".doc,.docx,.pdf,.xls,.jpg,.png" className="inputfile" id="file" name="file" type="file"/>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={isDragActive ? "upload-active" : "upload"}>
              <p>Faites glisser le fichier ici</p>
              <p>ou{" "} <span style={{ color: "#6CA21F" }}> parcourez votre{" "} {isTabletOrMobile ? "téléphone" : "ordinateur"}</span> </p>
            </div>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap", marginTop: 15 }}>
            {files.map((file, index) => {
              return (
                <div key={index}>
                  <div style={{ background: "#C2C2C1", marginRight: 5, borderRadius: 5, display: "flex", alignItems: "center", marginBottom: 10}}>
                    <p style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 5, paddingBottom: 5, margin: 0}}> 
                      {file.name} <FontAwesomeIcon onClick={() => handleFileDelete(index)} className="file-times" icon={faTimes}/>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

        </Box>
      </FormikStep>

      <FormikStep
        validationSchema={Yup.object({
          cgu: Yup.boolean().oneOf([true],"Vous devez accepter les conditions générales d'utilisation"),
          DUEsante: Yup.boolean().oneOf([true],"Vous devez accepter les conditions générales de DUE Santé"),
          //DUEprevoyance: Yup.boolean().oneOf([true],"Vous devez accepter les conditions générales de DUE prévoyance")
        })}
      >
        {loader ? 
        // <div style={{textAlign: "center",marginLeft: isTabletOrMobile ?"37vw" : "25vw" ,position:"absolute",top:"50%"}}>
        <div style={{textAlign: "center", height:"100%",display:"flex",justifyContent:"center",alignItems:"center",marginTop: isTabletOrMobile ? 100 : "30vh"}}>
          <CircularProgress style={{color:"#6CA21F"}} size={100}/>
          <p> Merci de patientier quelques instant ...</p>
        </div> :
          <>
            <Box>
              <h1>Législation relative</h1>
            </Box>
            <Box style={{ maxWidth: 800 }}>
              <p>Les informations concernant l’assuré sont utilisées conformément à la loi “Informatique et Libertés” du 5 janvier 1978.</p>
              <p>Elles sont exclusivement communiquées aux services de l’assureur et, le cas échéant, aux mandataires de ce dernier, aux assureurs ou organismes professionnels concernés par le contrat.</p>
              <p>L’assuré peut accéder aux informations le concernant et demander de procéder aux rectifications nécessaires auprès de: Green Santé - IGA GESTION - TSA 82111 - 37911 TOURS CEDEX 9.</p>

              <div style={{ marginTop: 50 }}>
                <p>A quel contrat souhaitez vous adhérer ?</p>
                <select className="contact-select" name="contract" id="contract-select" onChange={e => ContractAndFile(e) } value={contract} >
                    <option className="default-value" value="default"> Merci de bien vouloir choisir votre contract </option>
                    {allContract.map((val,key)=>(
                        <option value={val.contract_name}>
                          Contrat {val.contract_name} {val.values[0].contribution && " ( contribution " + val.values[0].contribution.priceEuro + " )"}
                        </option>
                    ))}
                </select>

                <p> {contract !== "default" && fileData.length !== 0 && "Information sur le contrat " + contract}</p>
                
                {contract !== "default" &&
                  fileData && fileData.map((val)=>(
                    <div className="RowDivLink">
                      <img src={linkIcon} alt="linkdue" />
                      <a className="linkParagraphStyle" href={val.url} target="_blank" rel="noreferrer">{val.fileName}</a>
                    </div> 
                  ))
                }
              </div>

              <div className="rowCgu" style={{ marginTop: 40 }}>
                <div className="iconsCheckBoxMesures" style={{ marginBottom: -10 }}>
                  <Field component={CustomCheckboxCGU} name="cgu" />
                </div>
                <p className="CheckParagraphStyle" style={{ fontSize: 17 }}>J’accepte les conditions de la demande d’adhésion</p>
              </div>
              <ErrorMessage className="error-message" name="cgu" component="div" />

              <div className="rowCgu">
                <div className="iconsCheckBoxMesures">
                  <Field component={CustomCheckboxCGU} name="DUEsante" />
                </div>
                <p className="CheckParagraphStyle" style={{ fontSize: 17 }}>Je reconnais avoir pris connaissance de la <b>DUE Santé</b> et de la <b> Notice d'informations Santé</b></p>
              </div>
              {/* <div className="RowDivLinkMain">
                <div className="RowDivLink">
                  <img src={linkIcon} alt="linkdue" />
                  <a className="linkParagraphStyle" href="/" target="_blank">DUE Santé</a>
                </div>

                <div className="RowDivLink">
                  <img src={linkIcon} alt="linksanteinfos" />
                  <a className="linkParagraphStyle" href="/" target="_blank">Notice d'informations Santé</a>
                </div>

              </div> */}
              
              <ErrorMessage className="error-message" name="DUEsante" component="div"/>
              {/* <div className="rowCgu">
                <div className="iconsCheckBoxMesures">
                  <Field component={CustomCheckboxCGU} name="DUEprevoyance" />
                </div>
                <p className="CheckParagraphStyle" style={{ fontSize: 17 }}>Je reconnais avoir pris connaissance de la <b>DUE Prévoyance</b>{" "} et de la <b> Notice d’informations Prévoyance</b></p>
              </div> */}
              {/* <div className="RowDivLinkMain">
                <div className="RowDivLink">
                  <img src={linkIcon} alt="linkdue" />
                  <a className="linkParagraphStyle" href="/" target="_blank">DUE Prévoyance</a>
                </div>

                <div className="RowDivLink">
                  <img src={linkIcon} alt="linksanteinfos" />
                  <a className="linkParagraphStyle" href="/" target="_blank">Notice d'informations Prévoyance</a>
                </div>
              </div> */}
              {/* <ErrorMessage className="error-message" name="DUEprevoyance" component="div"/> */}
            </Box>
          </>
        }
      </FormikStep>
    </FormikStepper>
  );

  //Custom formik input
  function CustomCheckboxCGU(props) {
    if (props.form.values[props.field.name]) return <CheckboxIconsSuccess id={props.field.name} handleCgu={props.form.setFieldValue} current={props.form.values[props.field.name]}/>
    else return <CheckboxIcons id={props.field.name} handleCgu={props.form.setFieldValue} current={props.form.values[props.field.name]} />
  }

  //Custom formik input
  function CustomDatePicker(props) {
    return (
      <MuiPickersUtilsProvider locale={frLocal} utils={DateFnsUtils}>
        <KeyboardDatePicker
          maxDate={new Date()}
          maxDateMessage="La date est supérieur à la date d'aujourd'hui"
          invalidDateMessage="Le format de la date invalide"
          format="dd-MM-yyyy"
          style={{ width: "100%" }}
          // id="date-picker-dialog"
          label="Date de naissance"
          inputVariant="outlined"
          value={props.field.value}
          onChange={(value) => {
            props.form.setFieldValue(props.field.name,value);
          }}
        />
      </MuiPickersUtilsProvider>
    );
  }

  function CustomDateStart(props) {
    return (
      <MuiPickersUtilsProvider locale={frLocal} utils={DateFnsUtils}>
        <KeyboardDatePicker
          minDate={new Date()}
          disablePast={true}
          minDateMessage="La date est inférieure à la date d'aujourd'hui"
          invalidDateMessage="Le format de la date invalide"
          style={{ width: "100%" }}
          // id="date-picker-dialog"
          label="Date du début de contrat"
          inputVariant="outlined"
          format="dd-MM-yyyy"
          value={props.field.value}
          onChange={(value) => {
            props.form.setFieldValue(props.field.name, value);
          }}
        />
      </MuiPickersUtilsProvider>
    );
  }

  //Custom formik input
  function CustomPhoneInput(props) {
    return (
      <PhoneInput inputClass={"test-class"} country={"fr"} placeholder={"+33 6 00 00 00 00"} specialLabel="Numéro de téléphone" localization={fr} value={props.field.value}
        inputStyle={{ width: "100%", borderRadius: 15, color: "#212121", fontFamily: "Raleway"}}
        onChange={(value) => { props.form.setFieldValue(props.field.name, value); }}
      />
    );
  }

  //Custom formik input
  function TextFieldAffiliationCode(props) {
    const [showModal, setShowModal] = useState(false);

    return (
      <>
        <div style={{ borderRadius: "17px", display: "flex", flexDirection: "row", flex: 2, marginRight: isTabletOrMobile ? 0 : 15, marginBottom: isTabletOrMobile ? 20 : 0 }} >

          <div style={{ flex: 3, marginRight: 3 }}>
            <Field fullWidth name={"cpamRegime"} variant="outlined" component={TextField} label="Régime" />
          </div>
          <div style={{ flex: 3, marginRight: 3 }}>
            <Field fullWidth name={"cpamCaisse"} variant="outlined" component={TextField} label="Caisse" />
          </div>
          <div style={{ flex: 3 }}>
            <Field fullWidth name={"cpamCentre"} variant="outlined" component={TextField} label="Guichet" />
          </div>

          <div style={{ height: "100%", marginTop: isTabletOrMobile ? 30 : "3vh", marginLeft: isTabletOrMobile ? 2 : 0 }}>
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" onClick={() => setShowModal(true)} edge="end">
                <FontAwesomeIcon icon={faInfoCircle} color="#848484" style={{ fontSize: 20 }} />
              </IconButton>
            </InputAdornment>
          </div>

          <Modal open={showModal} onClose={() => setShowModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box onClick={() => setShowModal(false)} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", borderRadius: 17 }}>
              <img style={{ width: isTabletOrMobile ? "100vw" : null, borderRadius: 17 }} src={affiliationImage} alt="Logo" />
            </Box>
          </Modal>
        </div>
        {/* <FormControl variant="outlined" style={{ width: "100%" }}>
          <InputLabel htmlFor="outlined-adornment" style={{ backgroundColor: "white", paddingLeft: 6, paddingRight: 6, marginLeft: -6}}>Code Affiliation</InputLabel>
          <OutlinedInput id="filled-adornment" value={props.field.value} type={"number"}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 15);
              props.form.setFieldValue(props.field.name,e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end" style={{ marginRight: 15 }}>
                <IconButton aria-label="toggle password visibility" onClick={() => setShowModal(true)} edge="end">
                  <FontAwesomeIcon icon={faInfoCircle} color="#848484" style={{ fontSize: 20 }}/>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        
        <Modal open={showModal} onClose={() => setShowModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box onClick={() => setShowModal(false)} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", borderRadius: 17}}>
            <img style={{width: isTabletOrMobile ? "100vw" : null,borderRadius: 17}} src={affiliationImage} alt="Logo"/>
          </Box>
        </Modal> */}
      </>
    );
  }
}

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, "children" | "validationSchema"> {}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const [nextStepLoading, setNextStepLoading] = useState(false);
  console.log("nextStepLoading -->",nextStepLoading);
  const currentChild = childrenArray[step] as React.ReactElement<FormikStepProps>;


  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isSmallLaptop = useMediaQuery({ query: "(max-width: 1700px)" });

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  
    return (
      <div style={{ background: "white", minHeight: "100vh" }}>
        <div style={{ paddingTop: 100, display: "flex" }}>
          {!isTabletOrMobile && (
            <div style={{ position: "relative", width: "400px" }}>
              <div style={{ alignItems: "center" }}>
                <div style={{position: "fixed",background: "#F3F7FA",paddingTop: 50,paddingLeft: 120,paddingRight: 120,height: "100%"}} >
                  <p style={{color: step <= 0 ? "#6CA21F" : "#9F9F9F",display: "flex",alignItems: "center",fontSize: 19,cursor: "pointer",fontWeight: step === 0 ? "bold" : "normal" }}
                    onClick={() => {
                      if (step > 0) {
                        setStep(0);
                      }
                    }}
                  >
                    {step <= 0 ? ( <FontAwesomeIcon icon={faCircle} color="#6CA21F" className="icon-step" /> ) : ( <FontAwesomeIcon icon={faCheckCircle} color="#6CA21F" className="icon-step"/>)}
                    Pour commencer{" "}
                    <span style={{ fontSize: 17, fontWeight: "normal", marginLeft: 5}}> (15s) </span>
                  </p>
  
                  <p style={{color: step === 1 ? "#6CA21F" : step > 1 ? "#9F9F9F" : "#000",display: "flex",alignItems: "center",fontSize: 19,cursor: "pointer",fontWeight: step === 1 ? "bold" : "normal" }}
                    onClick={() => {
                      if (step > 1) {
                        setStep(1);
                      }
                    }}
                  >
                    {
                      step === 1 ? ( <FontAwesomeIcon icon={faCircle} color="#6CA21F" className="icon-step" /> ) : step > 1 ? ( <FontAwesomeIcon icon={faCheckCircle} color="#6CA21F" className="icon-step"/> ) : ( <FontAwesomeIcon icon={faCircle} color="#9F9F9F" className="icon-step"/> )
                    }
                    Identification de l'assuré{" "}
                    <span style={{fontSize: 17,fontWeight: "normal",marginLeft: 5}}> (30s)</span>
                  </p>
  
                  <p style={{color:step === 2 ? "#6CA21F" : step > 2 ? "#9F9F9F" : "#000",display: "flex",alignItems: "center",fontSize: 19,cursor: "pointer",fontWeight: step === 2 ? "bold" : "normal"}}
                    onClick={() => {
                      if (step > 2) {
                        setStep(2);
                      }
                    }}
                  >
                    {
                      step === 2 ? ( <FontAwesomeIcon icon={faCircle} color="#6CA21F" className="icon-step"/> ) : step > 2 ? ( <FontAwesomeIcon icon={faCheckCircle} color="#6CA21F" className="icon-step" /> ) : ( <FontAwesomeIcon icon={faCircle} color="#9F9F9F" className="icon-step"/>)
                    }
                    Vos bénéficiaires{" "}
                    <span
                      style={{
                        fontSize: 17,
                        fontWeight: "normal",
                        marginLeft: 5
                      }}
                    >
                      (50s)
                    </span>
                  </p>
  
                  <p style={{color:step === 3 ? "#6CA21F" : step > 3 ? "#9F9F9F" : "#000", display: "flex", alignItems: "center", fontSize: 19, cursor: "pointer", fontWeight: step === 3 ? "bold" : "normal"}}>
                    {step === 3 ? <FontAwesomeIcon icon={faCircle} color="#6CA21F" className="icon-step"/> : step > 3 ? <FontAwesomeIcon icon={faCheckCircle} color="#6CA21F" className="icon-step"/> : <FontAwesomeIcon icon={faCircle} color="#9F9F9F" className="icon-step"/> }
                    Législation relative{" "} <span style={{ fontSize: 17, fontWeight: "normal", marginLeft: 5}}>(05s)</span>
                  </p>
                  <img height="400px" src={stepImage} alt="step" />
                </div>
              </div>
            </div>
          )}
  
          <div style={{background: "white",width: 500,flex: 1,marginLeft: isTabletOrMobile ? 16 : 300,marginRight: isTabletOrMobile ? 16 : isSmallLaptop ? 50 : "20%",paddingTop: isTabletOrMobile ? 15 : 50,paddingBottom: 200}}>
            <MuiThemeProvider theme={theme}>
              {isTabletOrMobile && (
                <>
                  <div style={{display: "flex",justifyContent: "space-around",alignItems: "center",marginLeft: 40,marginRight: 40}} >
                    <div style={{ width: "62%", position: "absolute", borderBottom: "1px solid #9F9F9F", zIndex: 0 }}></div>
                    <p style={{ zIndex: 1, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: step <= 0 ? "#6CA21F" : "#9F9F9F", display: "flex", flexDirection: "column", alignItems: "center", fontSize: 19, cursor: "pointer", fontWeight: step === 0 ? "bold" : "normal"}}
                      onClick={() => {
                        if (step > 0) {
                          setStep(0);
                        }
                      }}
                    >
                      { step <= 0 ? <FontAwesomeIcon icon={faCircle} color="#6CA21F" className="icon-step-active" /> : <FontAwesomeIcon icon={faCheckCircle} color="#6CA21F" className="icon-step-sc" /> }
                    </p>
  
                    <p style={{zIndex: 1,backgroundColor: "white",paddingLeft: 5,paddingRight: 5,color: step === 1 ? "#6CA21F" : step > 1 ? "#9F9F9F" : "#000",display: "flex",flexDirection: "column",alignItems: "center",fontSize: 19,cursor: "pointer",fontWeight: step === 1 ? "bold" : "normal"}}
                      onClick={() => {
                        if (step > 1) {
                          setStep(1);
                        }
                      }}
                    >
                      {step === 1 ?
                        <FontAwesomeIcon icon={faCircle} color="#6CA21F" className="icon-step-active" /> 
                        : step > 1 ? <FontAwesomeIcon icon={faCheckCircle} color="#6CA21F" className="icon-step-sc"/> : <FontAwesomeIcon icon={faCircle} color="#9F9F9F" className="icon-step-sc"/>
                      }
                    </p>
  
                    <p style={{zIndex: 1,backgroundColor: "white",paddingLeft: 5,paddingRight: 5,color: step === 2 ? "#6CA21F" : step > 2 ? "#9F9F9F" : "#000",display: "flex",flexDirection: "column",alignItems: "center",fontSize: 19,cursor: "pointer",fontWeight: step === 2 ? "bold" : "normal"}}
                      onClick={() => {
                        if (step > 2) {
                          setStep(2);
                        }
                      }}
                    >
                      {step === 2 ? 
                        <FontAwesomeIcon icon={faCircle} color="#6CA21F" className="icon-step-active"/>
                      : step > 2 ? <FontAwesomeIcon icon={faCheckCircle} color="#6CA21F" className="icon-step-sc" /> : <FontAwesomeIcon icon={faCircle} color="#9F9F9F" className="icon-step-sc"/>
                      }
                    </p>
  
                    <p style={{zIndex: 1,backgroundColor: "white",paddingLeft: 5,paddingRight: 5,color: step === 3 ? "#6CA21F" : step > 3 ? "#9F9F9F" : "#000",display: "flex",flexDirection: "column",alignItems: "center",fontSize: 19,cursor: "pointer",fontWeight: step === 3 ? "bold" : "normal"}} >
                      {step === 3 ? <FontAwesomeIcon icon={faCircle} color="#6CA21F" className="icon-step-active"/>
                       : step > 3 ? <FontAwesomeIcon icon={faCheckCircle} color="#6CA21F" className="icon-step-sc" /> : <FontAwesomeIcon icon={faCircle} color="#9F9F9F" className="icon-step-sc" />
                      }
                    </p>
                  </div>
                  <div style={{ textAlign: "center", color: "#6CA21F", fontWeight: "bold"}} >
                    <span style={{}}>{step === 0 && "Pour commencer"}</span>
                    <span style={{}}>
                      {step === 1 && "Identification de l'assuré"}
                    </span>
                    <span style={{}}>{step === 2 && "Vos bénéficiaires"}</span>
                    <span style={{}}>{step === 3 && "Législation relative"}</span>
                  </div>
                </>
              )}
              <Formik
                {...props}
                validationSchema={currentChild.props.validationSchema}
                onSubmit={async (values, helpers) => {
                  console.log("yes bby alors ca marche ?");
                  if (isLastStep() || props.otherForm) {
                    await props.onSubmit(values, helpers);
                  } else {
                    setNextStepLoading(true);
                    setTimeout(() => {
                      props.saveDatas();
                      setStep((s) => s + 1);
                      scrollToTop();
                      setNextStepLoading(false);
                    }, 1000);
                  }
                }}
                render={(values) => {
                  return (
                    <Form>
                      {currentChild}
  
                      <div style={{ marginTop: 50 }}>
                        {!isTabletOrMobile && 
                          <>
                            { props.loader === false &&
                              step > 0 ?
                                <button type="button" className={"button-return"} onClick={() => { setStep((s) => s - 1); scrollToTop(); }}>
                                  <FontAwesomeIcon icon={faAngleLeft} style={{ marginRight: 5 }} /> Retour
                                </button>
                              : null}
                          </>
                        }
  
                        {step === 0 && (props.otherForm || props.sendForm) ? 
                            <button className={isTabletOrMobile ? "button-submit-sc" : "button-submit"} type="submit">
                              {props.submitLoading ? (<Loader type="spinner-default" bgColor={"#aeed50"} size={20}/>) : ("Envoyer le formulaire" )}
                            </button>
                          : 
                            step === 0 && 
                              <button className={isTabletOrMobile ? "button-submit-sc" : "button-submit"} type="submit" >
                                {nextStepLoading ? ( <Loader type="spinner-default" bgColor={"#aeed50"} size={20}/> ) : ("Continuer vers l'identification")}
                              </button>
                        }
  
                        {step === 1 && 
                          <button className={isTabletOrMobile ? "button-submit-sc" : "button-submit" } type="submit" onClick={() => {setStep((s) => s + 1);}}>
                            {nextStepLoading ? ( <Loader type="spinner-default" bgColor={"#aeed50"} size={20} /> ) : ("Continuer vers les bénéficiaires")}
                          </button>
                        }
  
                        {step === 2 &&
                          <button className={isTabletOrMobile ? "button-submit-sc" : "button-submit-legislation"} type="submit" onClick={() => { setStep((s) => s + 1);}}>
                            {nextStepLoading ? (<Loader type="spinner-default" bgColor={"#aeed50"} size={20} />) : ("Continuer vers la législation relative")}
                          </button>
                        }
  
                        {step === 3 &&
                          props.loader === false &&
                          <>
                            <button
                              type={props.contract !== "default" ? "submit" : "button"}
                              className={
                                props.contract === "default" ? "button-primary-disabled" :
                                isTabletOrMobile &&
                                values.values.cgu &&
                                values.values.DUEsante &&
                                values.values.DUEprevoyance
                                  ? "button-submit-sc"
                                  : isTabletOrMobile &&
                                    !values.values.cgu &&
                                    !values.values.DUEsante 
                                    // !values.values.DUEprevoyance
                                  ? "button-submit-sc-disabled"
                                  : values.values.cgu &&
                                    values.values.DUEsante 
                                    // values.values.DUEprevoyance
                                  ? "button-primary"
                                  : "button-primary-disabled"
                              }
                            >
                              {props.submitLoading ? ( <Loader type="spinner-default" bgColor={"#aeed50"} size={20}/> ) : ("Je confirme mon adhésion")}
                            </button>
                            {props.messageErr.display && <p style={{color:"red",justifyContent:"end"}}>{props.messageErr.message}</p>}
                          </>
                        }
  
                        {isTabletOrMobile &&
                          <div style={{ width: "100%", textAlign: "center" }}>
                            {step > 0 ? <button type="button" className={"button-return-sc"} onClick={() => {setStep((s) => s - 1);scrollToTop();}}> Retour </button> : null}
                          </div>
                        }
                      </div>
                      <ConnectedFocusError />
                    </Form>
                  );
                }}
              ></Formik>
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    );
}

const theme = createTheme({
  typography: {
    fontFamily: "Raleway"
  },
  palette: {
    primary: { main: "#6CA21F" },
    secondary: { main: "#11cb5f" }
  },
  shape: {
    borderRadius: 15
  }
});