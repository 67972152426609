import React from "react";

import Card from "./Card";

import arrowLeft from "../../assets/img/arrowLeft.svg";

import "./styleRight.css";

export default function RightPart({ isTabletOrMobile, handleAdherent }) {
  const getColors = () => {
    return "#6CA21F";
  };

  return (
    <div className="rightBoxWeb">
      <div className="container2">
        <div className="titleDiv">
          <p className="titleText1">Diverses</p>
          <p className="titleText2">informations</p>
        </div>

        <div className="divTitleContainer">
          <p className="titleContainer">
            Votre entreprise participe pour la cotisation du salarié à hauteur
            de :
          </p>
        </div>

        <div className="cardDiversDiv">
          <Card name="Isolé" value={50} />
          <Card name="Duo" value={200} />
          <Card name="Famille" value={3000} />
        </div>

        <div className="divTitleContainer">
          <p className="titleContainer">
            Votre entreprise participe à hauteur de{" "}
            <b className="variableText" style={{ color: getColors() }}>
              50%
            </b>
            , il reste donc à votre charge :
          </p>
        </div>

        <div className="cardDiversDiv">
          <Card name="Isolé" value={50} />
          <Card name="Duo" value={200} />
        </div>

        <div className="buttonDiv">
          <button className="buttonAccess">
            <p className="buttonTextccess">Accéder au tableau de garanties</p>
          </button>
          {isTabletOrMobile && (
            <div className="backButtonMobile" onClick={() => handleAdherent(0)}>
              <img src={arrowLeft} height="26px" alt="arrowLeft" />
              <p>Retour</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
