import axios from 'axios'

import { API_URL_BACK } from "../../config"

axios.create({
    headers: {
        "Content-type": "application/json"
    }
});
axios.defaults.baseURL = API_URL_BACK

const formatDate = (date) => {
    return {
        dd: String(date.getDate()).padStart(2, '0'),
        mm: String(date.getMonth() + 1).padStart(2, '0'),
        yyyy: String(date.getFullYear())
    }
}

export const fetchListAsync = async (setExemptions, setCgu, regkey,setFiles) => {
    await axios.get(`/start/user/exemption?regkey=${regkey}`).then((e) => {
        const exemptions = e.data?.exemptions;
        const files = [];
        const foresight_file = e.data?.foresight_file;
        const health_file = e.data?.health_file;

        if (exemptions?.length) {
            exemptions.forEach(exemption => {
                files.push(exemption.user_exemption_status_file_infos!== undefined ? exemption.user_exemption_status_file_infos : null)
                exemption.check = false
                if (exemption.others_exemption === 1) {
                    exemption.others.forEach((other) => {
                        other.check = false
                    })
                }
            });
            setExemptions(exemptions)
            setFiles(files);
        }
        if (foresight_file && health_file) {
            setCgu({
                DUEsante: false,
                DUEPrevoyance: false,
                DUEsanteURL: foresight_file[0].url,
                DUEPrevoyanceURL: health_file[0].url
            })
        }
    }).catch((e) => console.error(e))
}

export const uploadFileAsync = async (file, regkey, handleFileId) => {
    const formData = new FormData()
    formData.set('registrationkey', regkey);
    formData.set('user_exemption_file_id', handleFileId);
    formData.append('file', file)
    await axios.post(`/start/user/file`,
        formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
        }
    ).then(({ data }) => handleFileId(data.file_id)).catch((e) => console.error(e))
}

export const submitNoAdhesionAsync = async (regkey, values, exemptionId, fileId, setOpen) => {
    await axios.post(`/start/user/exemption`, {
        registrationkey: regkey,
        email: values.email,
        firstname: values.firstName,
        lastname: values.lastName,
        hiring_date: formatDate(values.hiringDate),
        contracttype: values.cadre ? "Cadre" : "Non cadre",
        exemption_file_id: fileId,
        user_exemption_status_id: exemptionId
    }).then(() => setOpen(true)).catch((e) => console.error(e))
}