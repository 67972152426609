import React, {useState,useEffect} from "react";
import santeFilled from "../../assets/img/santeFilled.svg";
import sante from "../../assets/img/sante.svg";
import umbrellaFilled from "../../assets/img/umbrellaFilled.svg";
import umbrella from "../../assets/img/umbrella.svg";
import Info from "../../assets/img/informations.svg";
import {useParams} from "react-router-dom";
import axios from "axios";
import {API_URL_BACK} from "../../config";
import "./style.css";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Choose({ handleAdherent, isTabletOrMobile }) {
  const { regkey } = useParams();

  const [block1Hover, setBlock1Hover] = useState(false);
  const [block2Hover, setBlock2Hover] = useState(false);
  const [alreadyUse,setAlreadyUse] = useState(null);
  const [loader,setLoader] = useState(true);

  const handleHoverBlock1 = () => {
    setBlock1Hover(!block1Hover);
  };

  const handleHoverBlock2 = () => {
    setBlock2Hover(!block2Hover);
  };

  useEffect(()=>{
    //console.log("REGISTER", `${API_URL_BACK}/start/user/register?regkey=${regkey}`)
    axios.get(`${API_URL_BACK}/start/user/register?regkey=${regkey}`)
    .then((response) => {
      const access = response.data.access;
      console.log("access ->",access);
      if(access){
        setAlreadyUse(true);
        setLoader(false);
      }else{
        setAlreadyUse(false);
        setLoader(false);
      }
    })
    .catch((error) => {
      console.log("erreur ->",error);
    });
  },[]);

  return (
    <div className="container" style={{ height: isTabletOrMobile ? "100%" : "100vh" }} >
      <div className="flexColumn">
        {
          loader ? <CircularProgress style={{color:"#6CA21F"}} size={100}/>
          :
          alreadyUse ?
          <>
            <div className="alreadyUse" style={{ width: isTabletOrMobile && "98%",marginTop: isTabletOrMobile && 200}}>
              <img src={Info} alt="Information" height="60" width="100"/>
              <h1>Ce lien a déjà été utilisé !</h1>
              <p>Le lien ne peut ni être utilisé plusieurs fois, ni partagé à une tierce personne.
                <br></br>Pour souscrire à la mutuelle, demandez un nouveau lien à votre RH.
              </p>
              <span>A très vite !</span>
              <button type="button" className={'button-return'} style={{ fontSize: 16 }} onClick={() => {window.location.href = "https://www.green-sante.fr"}}>Retourner sur Green Santé</button>
            </div>
          </>
          : 
          <>
            <p className="welcome" style={{ marginTop: isTabletOrMobile ? "150px" : "-50px" }} >Bienvenue ! </p>
            <p className="subTitle" style={{marginRight: isTabletOrMobile && 20,marginLeft: isTabletOrMobile && 20 }}> Ce lien vous à été envoyé pour vous affilier à la mutuelle</p>
            
            <div className={isTabletOrMobile ? "flexColumn" : "flexHorizontal"}>
              <div className="block" style={{ width: isTabletOrMobile && "98%" }} onMouseOver={handleHoverBlock1} onMouseOut={handleHoverBlock1} onClick={() => handleAdherent(1)}>
                <img src={block1Hover ? santeFilled : sante} alt="umbrella" height="60" width="100"/>
                <p className="blockText">J'adhère à la mutuelle</p>
              </div>
              <div className="block" style={{ width: isTabletOrMobile && "98%" }} onMouseOver={handleHoverBlock2} onMouseOut={handleHoverBlock2} onClick={() => handleAdherent(2)}>
                <img src={block2Hover ? umbrellaFilled : umbrella} alt="umbrella" height="60" width="100"/>
                <p className="blockText">Je n'adhère pas à la mutuelle</p>
              </div>
            </div>
          </> 
        }
      </div>
    </div>
  );
}
