import React, {useState,useEffect} from "react";
import Upload from "./Upload";
import hoverCircle from "../../assets/img/hoverCircle.svg";
import normalCircle from "../../assets/img/normalCircle.svg";
import selectedCircle from "../../assets/img/selectedCircle.svg";
import arrowRight from "../../assets/img/arrowRight.svg";
import arrowBottom from "../../assets/img/arrowBottom.svg";
import "./styleRight.css";
import "./styleRightResponsive.css";

const Check = ({ idx1, idx2, text, other, handleSpecialsChecks }) => {
  const [hover, setHover] = useState(false);
  return (
    <div className="divRowDropdown" onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} onClick={() => handleSpecialsChecks(idx1, idx2)}>
      <img src={!other.check ? (hover ? hoverCircle : normalCircle) : selectedCircle} height="30px" className="iconsCircle" alt={toString(idx2) + "iconsCircle"}/>
      <p className="blockParagraph">{text}</p>
    </div>
  );
};

export default function SpecialCardDropdown1({idx,handleChecks,exemption,handleSpecialsChecks,isTabletOrMobile,handleFileId,regkey,files}) {
  const [hover, setHover] = useState(false);
  const [fileName,setFileName] = useState([]);

  useEffect(()=>{
    setFileName(files);
  },[]);

  return (
    <div id={idx} className="cardDropdown" style={{borderColor: exemption.check && "#6CA21F"}}>
      
      <div className="divRowDropdown" onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} onClick={() => handleChecks(idx)}>
        <div className="div-arrow-mobile">
          <img src={!exemption.check ? hover ? hoverCircle : normalCircle : selectedCircle} height="30px" className="iconsCircle" alt={toString(idx) + "iconsCircle"}/>
          <img src={exemption.check ? arrowBottom : arrowRight} height="25px" className="iconArrowMobile" alt={toString(idx) + "iconArrow"}/>
        </div>
        <p className="blockParagraph"><span dangerouslySetInnerHTML={{ __html: exemption.html }}/></p>
        <img src={exemption.check ? arrowBottom : arrowRight} height="25px" className="iconArrow" alt={toString(idx) + "iconArrow"}/>
      </div>

      {exemption.check && (
        <>
          <div className="divRowDropdownUpload">
            {exemption.others.map((other, idx2) => (
              <Check key={idx2} idx1={idx} idx2={idx2} other={other} text={other.html} handleSpecialsChecks={handleSpecialsChecks}/>
            ))}

            { fileName.map(val =>(
              <div className="divRowDropdownUpload">
                <p className="dropdownParagraph">Merci de déposer <b>{val.name}</b>. Les document accéptés  doivent être aux format JPEG, JPG, PNG ou PDF.</p>
                <Upload isTabletOrMobile={isTabletOrMobile} width={isTabletOrMobile ? "90%" : "50%"} handleFileId={handleFileId(val.user_exemption_status_file_id)} regkey={regkey}/>
              </div> ))
            }
          </div>
        </>
      )}

    </div>
  );
}
