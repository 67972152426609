import { useMediaQuery } from 'react-responsive'
import React from 'react'
import SuccessLogo from '../svg/SuccessLogo'

export default function Success() {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    return (
        <div style={{ height: '100vh', backgroundColor: '#F3F7FA', }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div style={{ backgroundColor: 'white', width: !isTabletOrMobile ? '50%' : '100%', textAlign: 'center', padding: 40, borderRadius: !isTabletOrMobile ? 40 : 0, marginTop: !isTabletOrMobile ? 0 : 100 }}>
                    <SuccessLogo />
                    <h2>Félicitation !</h2>
                    <p style={{ color: 'black', fontSize: '1em' }}>Vous venez de finaliser votre demande d’adhésion ! <br />Nous vous remercions de la confiance que vous nous accordez en nous confiant cette étude.</p>
                    <p style={{ color: 'black', fontSize: '1em' }}>Nous vous ferons un retour sous 72 heures pour vous annoncer si votre demande a été accordé ou non. Une fois la demande obtenu, les informations vous seront envoyé sur l’adresse mail de l’assuré.</p>
                    <p style={{ color: 'black', fontSize: '1em' }}>Nous restons à tout moment disponibles !</p>
                    <button type="button" className={'button-return'} style={{ fontSize: 14 }} onClick={() => {
                        window.location.href = "https://www.green-sante.fr"
                    }}>Retourner sur Green Santé</button>
                </div>
            </div>
        </div>
    )
}
