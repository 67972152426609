import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { uploadFileAsync } from "./api";
import "./styleRight.css";
import "./styleRightResponsive.css";

export default function Upload({isTabletOrMobile,width,multiple,regkey,handleFileId,exemption_status_file_id}) {
  const [files, setFiles] = useState([]);
  const onDrop = useCallback(
    async (acceptedFiles) => {
      let tempFiles = [];
      acceptedFiles.forEach((file) => {
        tempFiles.push(file);
      });
      setFiles(multiple ? tempFiles.concat(files) : tempFiles);
      if (!multiple) await uploadFileAsync(tempFiles[0], regkey, exemption_status_file_id);
    },
    [setFiles, handleFileId, files, multiple, regkey]
  );

  const handleFileDelete = (index) => {
    let tempFiles = [...files];
    tempFiles.splice(index, 1);
    setFiles(tempFiles);
    // handleFileId(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".doc,.docx,.pdf,.xls,.jpg,.png",
    multiple: multiple,
  });

  return (
    <>
      <div className={isDragActive ? "cardUpload cardUploadOnDrag" : "cardUpload"} style={{ width: width }} {...getRootProps()}>
        <input {...getInputProps()} />
        {!isTabletOrMobile ? (
          <p className="paragraphUpload">
            Faites glisser votre fichier ici ou{" "}
            <b className="greenSanteColorText">parcourez votre ordinateur</b>
          </p>
        ) : (
          <p className="paragraphUpload">Importer votre fichier</p>
        )}
      </div>

      <div style={{ display: "flex", flexWrap: "wrap", marginTop: 15 }}>
        {files?.map((file, index) => {
          return (
            <div className="uploaded-files" key={index}>
              <p className="uploaded-files-text">
                {file.name} <FontAwesomeIcon onClick={() => handleFileDelete(index)} className="file-times" icon={faTimesCircle}/>
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
}
