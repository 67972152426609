import React, { useState } from "react";

export const CheckboxIcons = ({ id, handleCgu, current }) => {
  const [hover, setHover] = useState(false);
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => handleCgu(id, !current)}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <path
        d="M7 2H27V0H7V2ZM32 7V27H34V7H32ZM27 32H7V34H27V32ZM2 27V7H0V27H2ZM7 32C4.23858 32 2 29.7614 2 27H0C0 30.866 3.13401 34 7 34V32ZM32 27C32 29.7614 29.7614 32 27 32V34C30.866 34 34 30.866 34 27H32ZM27 2C29.7614 2 32 4.23858 32 7H34C34 3.13401 30.866 0 27 0V2ZM7 0C3.13401 0 0 3.13401 0 7H2C2 4.23858 4.23858 2 7 2V0Z"
        fill={hover ? "#6CA21F" : "#C2C2C1"}
      />
    </svg>
  );
};

export const CheckboxIconsSuccess = ({ id, handleCgu, current }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => handleCgu(id, !current)}
  >
    <path
      d="M1 7C1 3.68629 3.68629 1 7 1H27C30.3137 1 33 3.68629 33 7V27C33 30.3137 30.3137 33 27 33H7C3.68629 33 1 30.3137 1 27V7Z"
      fill="#6CA21F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1331 11C23.766 11 23.4137 11.1453 23.1534 11.4041L14.7594 19.7603L10.4217 15.4227C10.1698 15.1556 9.82292 14.9979 9.45569 14.9839C9.08413 14.9696 8.72213 15.1036 8.44933 15.3562C8.17653 15.6089 8.01527 15.9596 8.00103 16.3311C7.98679 16.7027 8.12073 17.0647 8.37339 17.3375C8.37874 17.3433 8.3842 17.3489 8.38978 17.3545L14.3378 23.2867C14.5703 23.5185 14.9466 23.5185 15.179 23.2866L25.1129 13.3766C25.2429 13.2475 25.3461 13.0939 25.4166 12.9248C25.4871 12.7555 25.5235 12.5738 25.5235 12.3904C25.5235 12.2069 25.4871 12.0252 25.4166 11.8559C25.346 11.6866 25.2427 11.5328 25.1124 11.4037C24.8521 11.1451 24.5 11 24.1331 11Z"
      fill="white"
    />
    <path
      d="M7 2H27V0H7V2ZM32 7V27H34V7H32ZM27 32H7V34H27V32ZM2 27V7H0V27H2ZM7 32C4.23858 32 2 29.7614 2 27H0C0 30.866 3.13401 34 7 34V32ZM32 27C32 29.7614 29.7614 32 27 32V34C30.866 34 34 30.866 34 27H32ZM27 2C29.7614 2 32 4.23858 32 7H34C34 3.13401 30.866 0 27 0V2ZM7 0C3.13401 0 0 3.13401 0 7H2C2 4.23858 4.23858 2 7 2V0Z"
      fill="#6CA21F"
    />
  </svg>
);
