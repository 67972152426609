import React from 'react'

import "./NotFound.css"

export default function NotFound() {
    return (
        <div className="not-found">
            <h1 className="raleway text">404</h1>
            <h2 className="raleway text">La page à laquelle vous tentez d'acceder n'existe pas</h2>
            <button className="button-return-safe" onClick={() => window.location.href = "https://www.green-sante.fr"}>Retourner en lieu sûr</button>
        </div >
    )
}
