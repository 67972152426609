module.exports = {
    API_URL_BACK : process.env.REACT_APP_HTTP_API || "https://apiback.green-sante.com",
    regex : {
        passwordLength : /^.{6}$/,
        phone : /^((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))$/,
        // iban : /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/,
        iban : /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/,
        secu : /^[12][0-9]{2}[0-1][0-9](2[AB]|[0-9]{2})[0-9]{3}[0-9]{3}[0-9]{2}$/,
        regime : /^\d+$/,
        oneCharacter : /^.{1}$/,
        twoCharacter : /^.{2}$/,
        threeCharacter : /^.{3}$/,
        fourCharacter : /^.{4}$/,
        letterOnly : /^[a-zA-Z ]+$/,
        city : /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
    },
    initialValues : {
        other: "false",
        emailOther: "",
        transferEmail: "false",
        myEmail: "",
        bornName: "",
        lastname: "",
        firstname: "",
        phone: "",
        email: "",
        bornDate: null,
        dateStart : null,
        bornPlace: "",
        socialNumber: "",
        affiliationCode: "",
        iban: "",
        adress: "",
        postalCode: "",
        cpamRegime: "",
        cpamCaisse: "",
        cpamCentre: "",
        laser: "",
        password: "",
        confirmPassword: "",
        cpamCle: "",
        city: "",
        country: "",
        situation: 0,
        noemieSystem: "false",
        insurePartenaire: "false",
        partenaire: {
          firstname: "",
          lastname: "",
          bornDate: null,
          email: "",
          socialNumber: "",
          affiliation: "",
          rib: "",
          noemieSystem: "false"
        },
        haveChilds: "false",
        childs: [
          {
            firstname: "",
            lastname: "",
            bornDate: null,
            email: "",
            socialNumber: "",
            affiliation: "",
            rib: "",
            noemieSystem: "false",
            childAttachement: "1"
          }
        ],
        cgu: false,
        DUEsante: false,
        DUEprevoyance: false
      },
}