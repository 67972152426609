import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Choose from "./Choose";
import NotAdherent from "../NotAdherent";
import NeedInfos from "../NeedInfos";
import { MultiStepForm } from "../MultiStepForm.tsx";

export default function ChooseAdherent() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [adherent, setAdherent] = useState(0);
  //console.log("a quoi cela sert ->",adherent);
  const handleAdherent = (value) => {
    setAdherent(value);
  };

  const renderAdherent = () => {
    if (adherent === 0)
      return <Choose handleAdherent={handleAdherent} isTabletOrMobile={isTabletOrMobile}/>
    
      if (adherent === 1) return <MultiStepForm />
    
    if (adherent === 2)
      return <NotAdherent handleAdherent={handleAdherent} isTabletOrMobile={isTabletOrMobile}/>
    
      if (adherent === 3)
      return <NeedInfos handleAdherent={handleAdherent} isTabletOrMobile={isTabletOrMobile}/>
    
      return null;
  };

  return renderAdherent();
}
