import React from "react";
import Popup from "reactjs-popup";

import remerciement from "../../assets/img/remerciement.svg";

import "./styleRight.css";
import "./styleRightResponsive.css";

export default function PopUpNotAdhesion({ open, fileId }) {
  return (
    <Popup
      closeOnDocumentClick={false}
      modal
      open={open}
      position="center"
      lockScroll={false}
    >
      <div className="card-pop-up">
        <img src={remerciement} alt="remerciement" />
        <h2>Merci !</h2>
        <p style={{ textAlign: "center" }}>
          Nous vous remercions pour ces informations.
          <br />
          {fileId !== null ? (
            <>
              <br />
              Nous procédons à l’analyse de votre justificatif et nous vous
              confirmerons dans les prochains jours la recevabilité ou non de
              celui-ci.
            </>
          ) : (
            "Nous avons bien pris en compte votre demande."
          )}
        </p>
        {/* <p>
          {fileId
            ? "Nous procédons à l’analyse de votre justificatif et nous vous confirmerons dans les prochains jours la recevabilité ou non de celui-ci."
            : "Nous avons bien pris en compte votre demande."}
        </p> */}
        <div
          className="button-return-green-sante-pop-up"
          onClick={() => {
            window.location.href = "https://www.green-sante.fr";
          }}
        >
          Retourner sur Green Santé
        </div>
      </div>
    </Popup>
  );
}
