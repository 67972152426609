import React from "react";

import Loader from "react-js-loader";

import "./style.css";

export default function Loading({ title }) {
  return (
    <div className="loading-div">
      <Loader
        type="spinner-default"
        bgColor={"#6CA21F"}
        title={title}
        color={"black"}
        size={50}
      />
    </div>
  );
}
