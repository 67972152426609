import React from "react";

import arrowLeft from "../../assets/img/arrowLeft.svg";

import IllustrationNotAdherent from "../../assets/img/IllustrationNotAdherent.png";

import "./styleLeft.css";

export default function LeftPart({ handleAdherent }) {
  return (
    <div className="leftBoxWeb">
      <div className="backButton" onClick={() => handleAdherent(0)}>
        <img src={arrowLeft} height="26px" alt="arrowLeft" />
        <p>Retour</p>
      </div>
      <img
        className="illustration"
        height="600px"
        src={IllustrationNotAdherent}
        alt="IllustrationNotAdherent"
      />
    </div>
  );
}
