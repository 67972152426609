import { hot } from "react-hot-loader/root";
import React from "react";
import Navbar from "./components/navbar/Navbar";
import { MultiStepForm } from "./components/MultiStepForm.tsx";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFound from "./components/404/NotFound";
import Success from "./components/success/Success";
import ChooseAdherent from "./components/ChooseAdherent";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <div>
          <Switch>
            <Route path="/register/:regkey" component={ChooseAdherent} />
            <Route path="/success" component={Success} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default hot(App);
