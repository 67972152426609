import React from "react";

import LeftPart from "./LeftPart";
import RightPart from "./RightPart";

export default function NeedInfos({ handleAdherent, isTabletOrMobile }) {
  return (
    <div style={{ display: "flex", flexDirection: "row", overflowY: "hidden" }}>
      {!isTabletOrMobile && <LeftPart handleAdherent={handleAdherent} />}
      <RightPart
        isTabletOrMobile={isTabletOrMobile}
        handleAdherent={handleAdherent}
      />
    </div>
  );
}
