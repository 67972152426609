import React from "react";

import "./styleRight.css";

export default function Card({ name, value }) {
  return (
    <div className="cardDivers">
      <p className="Pname">{name}</p>
      <p className="Pvalue">{value} €</p>
    </div>
  );
}
