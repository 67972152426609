import React from "react";

import {
  MuiThemeProvider,
  createTheme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import * as Yup from "yup";
import { Field } from "formik";
import { TextField, RadioGroup } from "formik-material-ui";
import { Radio, FormControlLabel, FormControl } from "@material-ui/core";

import DatePicker from "../DatePicker";

import "./styleRight.css";
import "./styleRightResponsive.css";

const useStyles = makeStyles(() =>
  createStyles({
    smallRadioButton: {
      "& svg": {
        width: "30px",
        height: "30px",
      },
    },
  })
);

export const validationNotAdhesionForm = Yup.object({
  lastName: Yup.string().ensure().required("Vous devez renseigner votre nom"),
  firstName: Yup.string()
    .ensure()
    .required("Vous devez renseigner votre prénom"),
  email: Yup.string().ensure().required("Vous devez renseigner votre email"),
  hiringDate: Yup.date().required("Vous devez renseigner votre email"),
});

export const state = {
  firstName: "",
  lastName: "",
  email: "",
  hiringDate: null,
  cadre: "false",
};

export default function FormNotAdhesion({
  forceUpdate,
  formRef,
  isTabletOrMobile,
}) {
  const classes = useStyles();

  const handleHiringDate = (value) => {
    formRef.current.setFieldValue("hiringDate", value);
    forceUpdate();
  };

  const handleValidity = ({ target }) => {
    console.log(target);
    target.setCustomValidity(target.value + " n'est pas une email valide");
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className="form-row-div">
        <Field
          style={{ width: isTabletOrMobile ? "100%" : "45%" }}
          name="lastName"
          variant="outlined"
          component={TextField}
          label="Nom"
        />
        <div className="form-margin-style" />
        <Field
          style={{ width: isTabletOrMobile ? "100%" : "55%" }}
          name="firstName"
          variant="outlined"
          component={TextField}
          label="Prénom"
        />
      </div>
      <div className="form-row-div">
        <Field
          style={{ width: isTabletOrMobile ? "100%" : "70%" }}
          type="email"
          name="email"
          variant="outlined"
          component={(props) => (
            <TextField
              errorMessage={"trolololol"}
              onInvalid={handleValidity}
              {...props}
            />
          )}
          label="Adresse mail"
        />
        <div className="form-margin-style" />

        <Field
          name="hiringDate"
          label="Date d'embauche"
          handleChange={handleHiringDate}
          width={isTabletOrMobile ? "100%" : "30%"}
          variant="outlined"
          errorMessage="Veuillez choisir une date d'embauche"
          component={DatePicker}
        />
      </div>
      <FormControl component="fieldset">
        <Field component={RadioGroup} name={"cadre"}>
          <div className="form-margin-style-checks">
            <FormControlLabel
              value={"true"}
              name="cadre"
              control={<Radio color="primary" />}
              label={<p>Cadre</p>}
              className={classes.smallRadioButton}
            />
            <FormControlLabel
              value={"false"}
              name="cadre"
              control={<Radio color="primary" />}
              label={<p>Non cadre</p>}
              className={classes.smallRadioButton}
            />
          </div>
        </Field>
      </FormControl>
    </MuiThemeProvider>
  );
}

const theme = createTheme({
  typography: {
    fontFamily: "Raleway",
  },
  palette: {
    primary: { main: "#6CA21F" },
    secondary: { main: "#11cb5f" },
  },
  shape: {
    borderRadius: 15,
  },
});
