import React from "react";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import frLocal from "date-fns/locale/fr";

export default function CustomDatePicker({
  label,
  handleChange,
  width,
  field,
  form,
  errorMessage,
}) {
  return (
    <div
      style={{
        width: width ? width : "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <MuiPickersUtilsProvider locale={frLocal} utils={DateFnsUtils}>
        <KeyboardDatePicker
          maxDate={new Date()}
          style={{
            marginRight: 0,
            marginLeft: 0,
            borderColor: "red",
          }}
          id="date-picker-dialog"
          label={label}
          inputVariant="outlined"
          format="dd/MM/yyyy"
          value={field.value}
          onChange={(value) => {
            handleChange(value);
          }}
          error={form.errors?.hiringDate}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
      {form.errors?.hiringDate && (
        <div className="error-message-style">{errorMessage}</div>
      )}
    </div>
  );
}
