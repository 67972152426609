import React, {useState,useEffect} from "react";
import hoverCircle from "../../assets/img/hoverCircle.svg";
import normalCircle from "../../assets/img/normalCircle.svg";
import selectedCircle from "../../assets/img/selectedCircle.svg";
import "./styleRight.css";
import "./styleRightResponsive.css";

export default function SimpleCard({idx,handleChecks,exemption}) {
  const [hover, setHover] = useState(false);
  
  return (
    <div id={idx} key={idx + "simpleCard"} className="card" onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} onClick={() => handleChecks(idx)} style={{borderColor: exemption.check && "#6CA21F"}}>
      <img src={ !exemption.check ? hover ? hoverCircle : normalCircle : selectedCircle } height="30px" className="iconsCircle" alt={idx + "iconsCircle"} />
      <p className="blockParagraph"><span dangerouslySetInnerHTML={{ __html: exemption.html }} /></p>
    </div>
  );
}
