import React from 'react';

const SmallLogoType = (width, height) => {
  return (
    <div style={{ cursor: "pointer" }} onClick={() => window.location.href = "https://www.green-sante.fr"}>
      <svg width="29" height="43" viewBox="0 0 29 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.3038 18.4677L24.5386 15.7956L16.9998 19.7155C16.8597 19.788 16.7041 19.8259 16.5462 19.8259C16.3883 19.8259 16.2327 19.788 16.0927 19.7155C15.9653 19.6467 15.8585 19.5458 15.7832 19.4229C15.7078 19.2999 15.6666 19.1594 15.6636 19.0155V7.15234L20.9224 15.0652L23.2576 13.8113L14.2784 0.316895L2.253 18.4677C1.2758 19.916 0.596719 21.542 0.254852 23.2521C-0.0870161 24.9622 -0.0849177 26.7225 0.261024 28.4317C1.01978 31.9454 3.09176 35.0427 6.05921 37.0993L14.2784 42.9548L22.4976 37.0993C25.4677 35.0398 27.5399 31.9377 28.2958 28.4195C28.6384 26.7121 28.6388 24.9544 28.297 23.2468C27.9552 21.5392 27.2779 19.9152 26.3038 18.4677ZM20.7876 34.7741L15.6207 38.4262V25.7779C15.6074 25.1882 15.4282 24.6139 15.1032 24.1201C14.7782 23.6264 14.3205 23.2328 13.782 22.9841C13.2203 22.6988 12.5985 22.55 11.9677 22.55C11.3369 22.55 10.7151 22.6988 10.1535 22.9841L4.99887 25.6318L6.35954 27.7257L11.5142 25.084C11.6537 25.0099 11.8095 24.9712 11.9677 24.9712C12.126 24.9712 12.2817 25.0099 12.4213 25.084C12.5549 25.1457 12.6683 25.2435 12.7485 25.3662C12.8287 25.4889 12.8725 25.6316 12.8748 25.7779V38.414L7.70183 34.762C2.51043 31.1099 1.15588 24.5362 4.54531 19.4781L12.8748 7.06104V18.7963C12.8881 19.3861 13.0674 19.9603 13.3924 20.4541C13.7173 20.9479 14.175 21.3414 14.7136 21.5902C15.2743 21.8784 15.8965 22.0288 16.5278 22.0288C17.1592 22.0288 17.7813 21.8784 18.3421 21.5902L23.558 18.912L23.9502 19.5207C27.3581 24.5484 26.0035 31.1221 20.806 34.7741H20.7876Z" fill="#89C03D" />
      </svg>
    </div>
  );
}

export default SmallLogoType;