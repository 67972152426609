import React from 'react'
import { useMediaQuery } from 'react-responsive'
import "./Navbar.css"
import LogoType from '../svg/logotype';
import SmallLogoType from '../svg/SmallLogoType';

export default function Navbar() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isSmallMobile = useMediaQuery({ query: '(max-width: 600px)' })
    return (
        <nav>
            <div style={{ flex: 1 }}>
                {!isSmallMobile ?
                    <LogoType />
                    :
                    <SmallLogoType />
                }
            </div>
            <div style={{ flex: !isTabletOrMobile ? 1 : 2 }}>
                <div style={{ float: 'right', flexDirection: 'row' }}>
                    {/* <button className="button-connexion">Se connecter</button> */}
                    {!isTabletOrMobile &&
                        <button onClick={() => window.open('mailto:bonjour@green-sante.fr')} className="button-contact">Nous contacter</button>
                    }
                </div>
            </div>
        </nav>
    )
}
