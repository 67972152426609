import React, { useState, useCallback, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import SimpleCard from "./SimpleCard";
import DropDownUploadCard from "./DropDownUploadCard";
import UploadMultipl from "./UploadMultipl";
import SpecialCardDropdown1 from "./SpecialCardDropdown1";
import Footer from "./Footer";
import Loading from "../Loading";
import PopUpNotAdhesion from "./PopUpNotAdhesion";
import FormNotAdhesion, {validationNotAdhesionForm,state} from "./FormNotAdhesion";
import { fetchListAsync, submitNoAdhesionAsync } from "./api";
import "./styleRight.css";
import "./styleRightResponsive.css";

export default function RightPart({ isTabletOrMobile, handleAdherent }) {
  const formRef = useRef();
  const { regkey } = useParams();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [exemptions, setExemptions] = useState([]);
  const [files,setFiles] = useState([]);
  const [cgu, setCgu] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function fetchList(setExemptions, setCgu, regkey,setFiles) {
      await fetchListAsync(setExemptions, setCgu, regkey,setFiles);
    }
    fetchList(setExemptions, setCgu, regkey,setFiles);
  }, [setExemptions, setCgu, regkey,setFiles]);

  const handleSpecialsChecks = (idx1, idx2) => {
    const currentExempTmp = exemptions;
    currentExempTmp[idx1].others.forEach((other, idx) => {
      if (idx !== idx2) other.check = false;
    });
    currentExempTmp[idx1].others[idx2].check =
      !currentExempTmp[idx1].others[idx2].check;
    setExemptions(currentExempTmp);
    forceUpdate();
  };

  const handleChecks = (idx) => {
    const checksTmp = exemptions.map((exemption, id) => {
      if (idx !== id) exemption.check = false;
      return exemption;
    });
    checksTmp[idx].check = !checksTmp[idx].check;
    setExemptions(checksTmp);
    forceUpdate();
  };

  const handleFileId = (id) => {
    setFileId(id);
  };

  const handleCgu = (id) => {
    setCgu({ ...cgu, [id]: !cgu[id] });
  };

  const canSubmit = () => {
    const current = exemptions.filter(
      (exemption) => exemption.check === true
    )[0];

    if (current === undefined) return false;

    if (current.need_file && fileId === null) return false;

    if (current.others_exemption) {
      const currentOther = current.others.filter(
        (other) => other.check === true
      )[0];
      return currentOther === undefined ? false : cgu.DUEsante && cgu.DUEPrevoyance && currentOther?.check;
    }

    return cgu.DUEsante && current?.check;
  };

  const handleSubmit = async (values, actions) => {
    actions.resetForm();
    await submitNoAdhesionAsync(
      regkey,
      values,
      exemptions.filter((exemption) => exemption.check === true)[0]
        .user_exemption_status_id,
      fileId,
      setOpen
    );
  };

  const renderExemption = (exemption, idx) => {
    if (exemption["others_exemption"]) {
      return (
        <SpecialCardDropdown1
          key={idx + "SpecialCardDropdown1"}
          idx={idx}
          handleChecks={handleChecks}
          handleSpecialsChecks={handleSpecialsChecks}
          exemption={exemption}
          isTabletOrMobile={isTabletOrMobile}
          handleFileId={handleFileId}
          regkey={regkey}
          files={files[idx]}
        />
      );
    }
    
    if (exemption["user_exemption_status_file_ids"]) {
      return (
        <UploadMultipl
          idx={idx}
          key={idx + "DropDownUploadCard_"}
          handleChecks={handleChecks}
          exemption={exemption}
          isTabletOrMobile={isTabletOrMobile}
          handleFileId={handleFileId}
          regkey={regkey}
          files={files[idx]}
      />)
    }

    return (
      <SimpleCard key={idx + "simpleCard_"} idx={idx} handleChecks={handleChecks} exemption={exemption} />
    );
  
  };

  return exemptions === null || cgu === null ? (
    <Loading title="Veuillez patienter..." />
  ) : (
    <>
      <div className="rightBoxWeb">
        <div className="container2">
          <p className="titleText1">Ne pas adhérer à la mutuelle</p>
          <p className="subTitleText">
            Vous ne souhaitez pas adhérer à la mutuelle, dans ce cas, il est
            nécéssaire de nous préciser la raison et de vérifier si vous rentrez
            bien dans un des cas exonératoire admis par l'URSSAF
          </p>
          <p className="sub-title-checks">Votre raison de ne pas adhérer</p>

          {exemptions.map((exemption, idx) => renderExemption(exemption, idx))}

          <p className="sub-title-checks">Vos informations</p>
          <Formik initialValues={state} onSubmit={(values, actions) => { handleSubmit(values, actions); }} innerRef={formRef} validationSchema={validationNotAdhesionForm}>
            <Form>
              <FormNotAdhesion state={state} formRef={formRef} isTabletOrMobile={isTabletOrMobile} forceUpdate={forceUpdate}/>
              <Footer handleCgu={handleCgu} cgu={cgu} handleAdherent={handleAdherent} isTabletOrMobile={isTabletOrMobile} handleSubmit={handleSubmit} canSubmit={canSubmit}/>
            </Form>
          </Formik>

        </div>
      </div>
      <PopUpNotAdhesion open={open} setOpen={setOpen} fileId={fileId} />
    </>
  );
}