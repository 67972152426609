import React from "react";
import { CheckboxIcons, CheckboxIconsSuccess } from "./CheckboxIcons";
import linkIcon from "../../assets/img/Link.svg";
import arrowLeft from "../../assets/img/arrowLeft.svg";
import "./styleRight.css";
import "./styleRightResponsive.css";

export default function Footer({isTabletOrMobile,handleAdherent,cgu,handleCgu,canSubmit}) {
  return (
    <>
      <div className="RowDiv" style={{ marginTop: "30px" }}>
        <div className="iconsCheckBoxMesures">
          { cgu.DUEsante ? <CheckboxIconsSuccess id="DUEsante" handleCgu={handleCgu} /> : <CheckboxIcons id="DUEsante" handleCgu={handleCgu} /> }
        </div>
        <p className="CheckParagraphStyle" onClick={() => handleCgu("DUEsante")}>Je reconnais avoir pris connaissance de la <b>DUE Santé</b> et de la <b> Notice d'informations Santé</b></p>
      </div>

      {/* <div className="RowDivLinkMain">
        <div className="RowDivLink">
          <img className="linkIcons" src={linkIcon} alt="linkdue" />
          <a className="linkParagraphStyle" href={cgu.DUEsanteURL} target="_blank" rel="noreferrer">DUE Santé</a>
        </div>

        <div className="RowDivLink">
          <img className="linkIcons" src={linkIcon} alt="linksanteinfos" />
          <a className="linkParagraphStyle" href="/" target="_blank" rel="noreferrer">Notice d'informations Santé</a>
        </div>
      </div> */}

      {/* <div className="RowDiv" style={{ marginTop: "10px" }}>
        <div className="iconsCheckBoxMesures">
          { cgu.DUEPrevoyance ? <CheckboxIconsSuccess id="DUEPrevoyance" handleCgu={handleCgu} /> : <CheckboxIcons id="DUEPrevoyance" handleCgu={handleCgu} /> }
        </div>
        <p className="CheckParagraphStyle" onClick={() => handleCgu("DUEPrevoyance")} >Je reconnais avoir pris connaissance de la <b>DUE Prévoyance</b> et de la<b> Notice d’informations Prévoyance</b></p>
      </div> */}

      {/* <div className="RowDivLinkMain">
        <div className="RowDivLink">
          <img className="linkIcons" src={linkIcon} alt="linkdue" />
          <a className="linkParagraphStyle" href={cgu.DUEPrevoyanceURL} target="_blank" rel="noreferrer">DUE Prévoyance</a>
        </div>

        <div className="RowDivLink">
          <img className="linkIcons" src={linkIcon} alt="linksanteinfos" />
          <a className="linkParagraphStyle" href="/" target="_blank" rel="noreferrer" >Notice d'informations Prévoyance</a>
        </div>
      </div> */}

      <div>
        <p>
          Dans le cadre de votre contrat de prévoyance, vous avez la possibilité
          de désigner un bénéficiaire en cas de décès. Si vous ne faite rien, la
          clause contractuelle s'appliquera, à savoir mon conjoint, à défaut mes
          enfants, à défaut mes héritiers par parts égales.
        </p>
        <p>
          Bénéficiez dès maintenant de votre{" "}
          <a href={cgu.DUEPrevoyanceURL} target="_blank" rel="noreferrer">
            Contrat de Prévoyance
          </a>
        </p>
      </div>

      <div className="buttonDiv">
        <button disabled={!canSubmit()} className="buttonSend" type="submit" style={{backgroundColor: canSubmit() && "#6CA21F", borderColor: canSubmit() && "#6CA21F"}} >
          <p className="buttonText">Envoyer les informations</p>
        </button>

        {isTabletOrMobile &&
          <div className="backButtonMobile" onClick={() => handleAdherent(0)}>
            <img src={arrowLeft} height="26px" alt="arrowLeft" />
            <p>Retour</p>
          </div>
        }
      </div>
    </>
  );
}
