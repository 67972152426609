import React, {useState,useEffect} from "react";
import hoverCircle from "../../assets/img/hoverCircle.svg";
import normalCircle from "../../assets/img/normalCircle.svg";
import selectedCircle from "../../assets/img/selectedCircle.svg";
import arrowRight from "../../assets/img/arrowRight.svg";
import arrowBottom from "../../assets/img/arrowBottom.svg";
import Upload from "./Upload";
import "./styleRight.css";
import "./styleRightResponsive.css";

export default function UploadMultipl({idx,handleChecks,exemption,isTabletOrMobile,handleFileId,regkey,files}) {
  
  const [hover, setHover] = useState(false);
  const [fileName,setFileName] = useState([]);

  useEffect(()=>{
    setFileName(files);
  },[]);

  return (
    <div id={idx} className="cardDropdown" style={{borderColor: exemption.check && "#6CA21F"}} >
      <div className="divRowDropdown" onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} onClick={() => handleChecks(idx)}>
        <div className="div-arrow-mobile">
          <img src={!exemption.check ? hover ? hoverCircle : normalCircle : selectedCircle} height="30px" className="iconsCircle" alt={"iconsCircle"}/>
          <img src={exemption.check ? arrowBottom : arrowRight} height="25px" className="iconArrowMobile" alt={"iconArrow"}/>
        </div>
        <p className="blockParagraph"><span dangerouslySetInnerHTML={{ __html: exemption.html }} /></p>
        <img src={exemption.check ? arrowBottom : arrowRight} height="25px" className="iconArrow" alt={"iconArrow"}/>
      </div>
      
      { exemption.check && 
        fileName.map(val =>(
          <div className="divRowDropdownUpload">
            <p className="dropdownParagraph">Merci de déposer <b>{val.name}</b>. Les photos au format JPEG, JPG, PNG ou un document au format PDF sont acceptés.</p>
            <Upload isTabletOrMobile={isTabletOrMobile} width={isTabletOrMobile ? "90%" : "50%"} handleFileId={handleFileId(val.user_exemption_status_file_id)} regkey={regkey} exemption_status_file_id={val.user_exemption_status_file_id}/>
        </div>
        ))
      }

    </div>
  );
}
